import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface LeadProps {}

const PricesLead: FC<LeadProps> = () => {
    const { t } = useTranslation();
    return (
        <>
            <Typography component="h2" variant="h6">{ t('pricing-calculator') }</Typography>
            <Typography paragraph>{ t('pricing-lead-text') }</Typography>
        </>
)};

export default PricesLead;
