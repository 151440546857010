import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import ThreeColumnsLayout from '../../layout/ThreeColumnsLayout/ThreeColumnsLayout';
import PricesSelectsForm from '../../components/PricesSelects/PricesSelectsForm';
import PricesSolutionsForm from '../../components/PricesSolutions/PricesSolutionsForm';
import PricesTotal from '../../components/PricesTotal/PricesTotal';
import PricesLead from '../../components/PricesLead';
import { priceSolutionsState } from '../../recoil/atoms/price-solutions';
import { PROD_HOST_NAME, RU_PROD_HOST_NAME } from '../../appConstants';
import {
    IInfo,
    OptionsType,
    IVerifyFormInputs,
    PriceSolutionsType,
    IPriceSolutionsInputs
} from '../../types';
import getUserInstance from '../../model/user';
import { getSolutions, getPrice } from './requests';
import './Prices.scss';

type ReceivedSolutionsType = {
    code: string,
    name: string,
};

const User = getUserInstance();
const PricesPage: FC<IPricesPageProps> = () => {
    const [vehicleOptions, setVehicleOptions] = useState<OptionsType>([]);
    const [makeOptions, setMakeOptions] = useState<OptionsType>([]);
    const [modelOptions, setModelOptions] = useState<OptionsType>([]);
    const [engineOptions, setEngineOptions] = useState<OptionsType>([]);
    const [ecuOptions, setEcuOptions] = useState<OptionsType>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<IInfo>({ id: '', name: '' });
    const [selectedMake, setSelectedMake] = useState<IInfo>({ id: '', name: '' });
    const [selectedEngine, setSelectedEngine] = useState<IInfo>({ id: '', name: '' });
    const [selectedEcu, setSelectedEcu] = useState<IInfo>({ id: '', name: '' });
    const [selectedModel, setSelectedModel] = useState<IInfo>({ id: '', name: '' });
    const [isSelectsLoader, setIsSelectsLoader] = useState(false)
    const [calculatedPrice, setCalculatedPrice] = useState('');
    const [currency, setCurrency] = useState('USD');
    const [selectedSolutions, setSelectedSolutions] = useState<string[]>([]);
    const [isSolutionsChanged, setIsSolutionsChanged] = useState(false);
    const [isSolutionsLoader, setIsSolutionsLoader] = useState(false);
    const [isSelectsFormDisabled, setIsSelectsFormDisabled] = useState(false);
    const [isSelectsChanged, setIsSelectsChanged] = useState(false);
    const [isSolutionsFormDisabled, setIsSolutionsFormDisabled] = useState(false);
    const [availableSolutions, setAvailableSolutions] = useRecoilState(priceSolutionsState);

    useEffect(() => {
        User.getOptions({ name: 'types' })
        .then((data: OptionsType) => {
            setVehicleOptions(data);
        })
    }, []);

    useEffect(() => {
        const codes = availableSolutions.reduce((accum, { code, isSelected }) => {
            if (isSelected === true) {
                accum.push(code);
                return accum;
            }
            return accum;
        }, [] as string[]);
        setSelectedSolutions(codes);
        setIsSolutionsChanged(true);
        if (codes.length === 0) {
            setCalculatedPrice('');
        }
    }, [availableSolutions]);

    useEffect(() => {
        const isSelectsDisabled = !selectedEcu.id || isSelectsLoader || !isSelectsChanged;
        setIsSelectsFormDisabled(isSelectsDisabled);
    }, [selectedEcu, isSelectsChanged, isSelectsLoader]);

    useEffect(() => {
        const isSolutionsDisabled = selectedSolutions.length === 0 || isSolutionsLoader || !isSolutionsChanged;
        setIsSolutionsFormDisabled(isSolutionsDisabled);
    }, [selectedSolutions, isSolutionsLoader, isSolutionsChanged]);

    function selectOptionHandler(e) {
        const { target } = e;
        const { name, value } = target;
        setSelectsData({ name, value });
        setAvailableSolutions([]);
        setCalculatedPrice('');
        setIsSelectsChanged(true);
    }

    function selectsHandler(data: IVerifyFormInputs) {
        setIsSelectsLoader(true);
        getSolutions(data.ecu)
        .then((solutions: ReceivedSolutionsType[]) => {
            const formedResults: PriceSolutionsType[] = solutions.map(({ code, name }) => ({
                name,
                code,
                isSelected: false,
            }));
            setAvailableSolutions(formedResults);
        })
        .finally(() => {
            setIsSelectsLoader(false);
            setIsSelectsChanged(false);
        });
    }

    function solutionsHandler(data: IPriceSolutionsInputs) {
        const res = Object.entries(data);
        const codes = res.reduce((accum, [key, value]) => {
            if (value === true) {
                accum.push(key);
                return accum;
            }
            return accum;
        }, [] as string[]);
        setIsSolutionsLoader(true);
        getPrice(selectedEngine.id, selectedEcu.id, codes.join(','))
        .then((price: string) => {
            setCalculatedPrice(price);
            const cur = PROD_HOST_NAME === RU_PROD_HOST_NAME ? 'RUB' : 'USD';
            setCurrency(cur);
            setIsSolutionsChanged(false);
        })
        .finally(() => setIsSolutionsLoader(false));
    }
  
    function setSelectsData({ name, value }: { name: string, value: string }) {
        const setterCollection = {
          vehicle: {
                setter: setSelectedVehicle,
                next: 'makes',
                paramName: 'vehicleTypeId',
                nextOptionSetter: setMakeOptions,
                emptySetters: [setModelOptions, setEngineOptions, setEcuOptions],
                defaultSetters: [setSelectedMake, setSelectedModel, setSelectedEngine, setSelectedEcu],
            },
            make: {
                setter: setSelectedMake,
                next: 'models',
                paramName: 'makeId',
                nextOptionSetter: setModelOptions,
                emptySetters: [setEngineOptions, setEcuOptions],
                defaultSetters: [setSelectedModel, setSelectedEngine, setSelectedEcu],
            },
            engine: {
                setter: setSelectedEngine,
                next: 'ecus',
                paramName: 'engineId',
                nextOptionSetter: setEcuOptions,
                emptySetters: [],
                defaultSetters: [setSelectedEcu],
            },
            ecu: {
                setter: setSelectedEcu,
                next: '',
                paramName: '',
                nextOptionSetter: null,
                emptySetters: [],
                defaultSetters: [],
            },
            model: {
                setter: setSelectedModel,
                next: 'engines',
                paramName: 'modelId',
                nextOptionSetter: setEngineOptions,
                emptySetters: [setEcuOptions],
                defaultSetters: [setSelectedEngine, setSelectedEcu],
            }
        };
        const {
            setter,
            next,
            nextOptionSetter,
            paramName,
            emptySetters,
            defaultSetters
        } = setterCollection[name];
        setter({ id: value, name });
        if (next) {
            if (value) {
                const paramData = {
                    name: next,
                    param: {
                        id: value,
                        paramName,
                    }
                }
                User.getOptions(paramData)
                .then((data: OptionsType) => {
                    if (nextOptionSetter) {
                        nextOptionSetter(data);
                    }
                })
                .catch(() => {
                    if (nextOptionSetter) {
                        nextOptionSetter([]);
                    }
                })
            } else {
                nextOptionSetter([]);
            }
        }
        if (defaultSetters.length) {
            defaultSetters.forEach(setter => setter({ id: '', name: '' }));
        }
        if (emptySetters.length) {
            emptySetters.forEach(setter => setter([]));
        }
    }

    return (
    <>
        <ThreeColumnsLayout
            lead={<PricesLead />}
            left={<PricesSelectsForm
                selects={[{
                    name: 'vehicle',
                    options: vehicleOptions,
                    selectedValue: selectedVehicle,
                },{
                    name: 'make',
                    options: makeOptions,
                    selectedValue: selectedMake,
                },{
                    name: 'model',
                    options: modelOptions,
                    selectedValue: selectedModel,
                },{
                    name: 'engine',
                    options: engineOptions,
                    selectedValue: selectedEngine,
                },{
                    name: 'ecu',
                    options: ecuOptions,
                    selectedValue: selectedEcu,
                }]}
                selectHandler={selectOptionHandler}
                formHandler={selectsHandler}
                isDisabledButton={isSelectsFormDisabled}
                isLoader={isSelectsLoader}
            />}
            middle={ availableSolutions.length ? <PricesSolutionsForm
                submitFormHandler={solutionsHandler}
                isLoader={isSolutionsLoader}
                isDisabled={isSolutionsFormDisabled}
            /> : '' }
            right={ calculatedPrice ? <PricesTotal
                subtotal={calculatedPrice}
                currency={currency}
            /> : '' }
        />
    </>
)}

interface IPricesPageProps extends RouteComponentProps<{}> {}

export default PricesPage;
