import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import AFButton from '../AFButton/AFButton';
import ButtonErrorMesage from '../ButtonErrorMessage/ButtonErrorMessage';
import { VerifyFormFieldType, VerifyFormErrorsType } from '../../types';
import { BLACK_TEXT_COLOR } from '../../appConstants';
import './SourceFileUploader.scss';

interface SourceFileUploaderProps {
  control: VerifyFormFieldType,
  errors: VerifyFormErrorsType,
  setFileContent: React.Dispatch<React.SetStateAction<File>>,
}

const SourceFileUploader: FC<SourceFileUploaderProps> = ({ control, errors, setFileContent }) => {
  const { t } = useTranslation();
  const uploadInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('no-file');

  function loadFile() {
    uploadInput?.current?.click();
  }

  return (
    <div className="SourceFileUploader">
      {t('source-file')}:
      <Controller
        control={control}
        name="fileinput"
        defaultValue=""
        rules={{ required: 'input-file-required' }}
        render={({ field: { onChange, name, value } }) => {
          const message = errors.fileinput && errors.fileinput.message ? errors.fileinput.message : '';
          return (
            <>
              <input
                className="hidden-input"
                id={name}
                type="file"
                ref={uploadInput}
                name={name}
                value={value}
                onChange={(e) => {
                  if (uploadInput?.current?.files?.length) {
                    const file = uploadInput.current.files[0];
                    setFileName(file.name);
                    setFileContent(file);
                  }
                  onChange(e);
                }}
              />
              { message && 
                <ButtonErrorMesage text={t(message)}/>
              }
            </>
          )
        }}
      />
      <div className="uploadFile">
        <AFButton
          variant="outlined"
          onClick={loadFile}
          textColor={BLACK_TEXT_COLOR}
        >
          {t('select-file')}...
        </AFButton>
        <span className="file-name">{t(fileName)}</span>
      </div>
    </div>
)};

export default SourceFileUploader;
