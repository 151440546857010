import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { authState } from '../../recoil/atoms/authorize';
import Alert from '../../components/Alert/Alert';
import RegisterUser from '../../components/RegisterUser/RegisterUser';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';
import InvitationToRegistration from '../../components/InvitationToRegistration/InvitationToRegistration';
import { FormDataType } from '../../types';
import getUserInstance from '../../model/user';
import parseErrors from '../../utils/parse-error-messages';
import './RegisterPage.scss';

const User = getUserInstance();
const REGISTER_ERROR_KEY = 'register-error-message';

const RegisterPage: FC<IRegisterPageProps> = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabledForm, setDisableForm] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const isAuthorized = useRecoilValue(authState);
    const { i18n, t } = useTranslation();

    async function register(data: FormDataType) {
        const { username, password, country, marketingConsent } = data;
        setDisableForm(true);
        const token = await window.grecaptcha.getResponse();
        if (!token) {
          setErrorMessage('captcha-failed');
          return;
        }
        User.register({ username, password, country, token, marketingConsent })
        .then(() => {
          setDisableForm(false);
          setIsRegistered(true);
        })
        .catch((e) => {
          const message = parseErrors(e, REGISTER_ERROR_KEY, i18n.exists);
          setErrorMessage(message);
        })
    }

    function clearErrorMessage() {
        setDisableForm(false);
        setErrorMessage('');
    }

    useEffect(() => {
        if (isAuthorized) {
            window.location.href = '/tasks';
        }
    });

    return (
        <>
            { !isAuthorized && 
                <OneColumnLayout>
                    <DefaultGreeting greetingText="registration-greeting-text"/>
                    <RegisterUser
                        handler={register}
                        isDisabled={isDisabledForm}
                        isRegistered={isRegistered}
                        clear={clearErrorMessage}
                    />
                    <InvitationToRegistration
                        text="login-invitation"
                        href="/login"
                    />
                    { isRegistered &&
                        <div className="registered-notification-wrapper">
                            <Alert
                            icon={<CheckCircleOutlineOutlined/>}
                            color='success'
                            text='registered-successfully'
                            />
                        </div>
                    }
                    { errorMessage &&
                        <div className='register-error-message'>
                            <p>{t(errorMessage)}</p>
                        </div>
                    }
                </OneColumnLayout>
            }
        </>
    );
}

interface IRegisterPageProps extends RouteComponentProps<{}> {}

export default RegisterPage;
