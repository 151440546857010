import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import AFLogo from '../AFLogo/AFLogo';
import UserMenu from '../UserMenu/UserMenu';
import BasicButton from '../BasicButton/BasicButton';
import TasksButton from '../TasksButton/TasksButton';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import { authState } from '../../recoil/atoms/authorize';
import './NavBar.scss';

interface NavBarProps {};

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      display: 'flex',
      marginRight: '25px',
      color: '#000',
      '&:hover': {
        textDecoration: 'none',
      }
    },
  }),
);

const NavBar: FC<NavBarProps> = () => {
  const isAuth = useRecoilValue(authState);
  const classes = useStyles();
  const location = useLocation();
  const isRegisterPage = location.pathname === '/register';
  const isLoginPage = location.pathname === '/login';
  return (
    <nav className="nav-bar">
      <span className="link-block">
        <Link href="/" className={classes.link}><AFLogo/></Link>
        { isAuth && <BasicButton text="create-task" href="/create-task"/> }
      </span>
      <span className="personal-block">
      { isAuth && 
        <>
          <TasksButton/>
          <UserMenu/>
        </> 
      }
      {
        !isAuth && (!isRegisterPage && !isLoginPage) && <BasicButton text="log-in" href="/login"/>
      }
        <LanguagePicker/>
      </span>
    </nav>
  );
}

export default NavBar;
