import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import { langState } from '../../recoil/atoms/lang';
import { POLICY_CONFIG } from '../../appConstants';
import './WarningAboutTermsOfUse.scss';

interface WarningAboutTermsOfUseProps {}

const WarningAboutTermsOfUse: FC<WarningAboutTermsOfUseProps> = () => {
  const { t } = useTranslation();
  const lang = useRecoilValue(langState);
  const policyList = POLICY_CONFIG[lang];
  return (
  <div className="WarningAboutTermsOfUse">
    <div className="WarningAboutTermsOfUse-text-wrapper">
      <span className="WarningAboutTermsOfUse--inline">{t('agree-to-policy')}</span>
      {policyList.map(({ key, link }, index, arr) => {
        const isLast = index === arr.length - 1;
        const isLastBeforeLast = index === arr.length - 2;
        if (isLast) {
          return (
            <span key={link}>
              <span className="WarningAboutTermsOfUse--inline">{t('and')}</span>
              <span className="WarningAboutTermsOfUse--inline">
                <Link
                  href={link}
                  target="_blank"
                >{t(key)}</Link>.
              </span>
            </span>
          )
        }
        if (isLastBeforeLast && lang === 'ru') {
          return (
            <span key={link} className="WarningAboutTermsOfUse--inline">
              <Link
                href={link}
                target="_blank"
              >{t(key)}</Link>
            </span>
          )
        }
        return (
          <span key={link} className="WarningAboutTermsOfUse--inline">
            <Link
              href={link}
              target="_blank"
            >{t(key)}</Link>,
          </span>
      )})}
    </div>
  </div>
)};

export default WarningAboutTermsOfUse;
