import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../recoil/atoms/authorize';
import VehicleInfoCell from '../VehicleInfoCell/VehicleInfoCell';
import getUserInstance from '../../model/user';
import { ITask } from '../../types';
import DownloadTaskFile from '../DownloadTaskFile/DownloadTaskFile';
import './TasksTable.scss';

const User = getUserInstance();

interface ITasksTableProp {
  tasksList: ITask[],
  errorMessage: string,
}

export default function TasksTable (props: ITasksTableProp) {
  const authToken = useRecoilValue(authState);
  const { t } = useTranslation();

  function handleTaskClick(e, id) {
    const { target } = e;
    if (target.closest('.task-link')) {
      window.open(`/task/${id}`, '_top');
    } else if (target.tagName === 'A') {
      e.preventDefault();
      User.downloadTaskFile(id, authToken);
    }
  }
  return (
    <>
      {props.tasksList.length && props.tasksList.every(item => item.id) ?
        <TableContainer component={Paper}>
          <Table aria-label="tasks list">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{t('created')}</TableCell>
                <TableCell>{t('vehicle')}</TableCell>
                <TableCell>{t('status')}</TableCell>
                <TableCell>{t('download')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.tasksList.map((task) => (
                <TableRow
                  className="task-row"
                  key={task.id}
                  onClick={e => handleTaskClick(e, task.id)}
                >
                  <TableCell className="task-link">{task.id}</TableCell>
                  <TableCell className="task-link">{task.dateTime}</TableCell>
                  <VehicleInfoCell info={task.vehicleInfo}/>
                  <TableCell className="task-link">{t(task.status.name)}</TableCell>
                  <TableCell>
                    {
                      task.allowDownload &&
                      <DownloadTaskFile id={task.id}/>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> :
        <p>{t('no-tasks')}</p>
      }
      { props.errorMessage && <p>{t(props.errorMessage)}</p>}
    </>
  )
}
