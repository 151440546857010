import React, { FC } from 'react';
import BasicButton from '../BasicButton/BasicButton';
import ButtonErrorMessage from '../ButtonErrorMessage/ButtonErrorMessage';
import { ButtonType, ButtonHandlerType, InlineStylesType } from '../../types';
import './APIButton.scss';

interface APIButtonProps {
  isDisabled: boolean,
  handler?: ButtonHandlerType,
  buttonText: string,
  errorMessage?: string,
  type?: ButtonType,
  isLoader?: boolean,
  styles?: InlineStylesType,
}

const APIButton: FC<APIButtonProps> = ({ isDisabled, handler, buttonText, errorMessage, type, isLoader, styles }) => (
  <div className="APIButton">
    { handler ?
        <BasicButton
          styles={styles}
          type={type}
          disabled={isDisabled}
          text={buttonText}
          onClick={() => handler()}
          fullWidth
          isLoader={isLoader}
        /> : 
        <BasicButton
          styles={styles}
          type={type}
          disabled={isDisabled}
          text={buttonText}
          fullWidth
          isLoader={isLoader}
        />
    }
    { errorMessage ? <ButtonErrorMessage
      text={errorMessage}
    /> : '' }
  </div>
);

APIButton.defaultProps = {
  handler: () => {},
  type: 'button',
  isLoader: false,
  styles: {},
  errorMessage: '',
};

export default APIButton;
