import React, { useState, useEffect } from 'react';
import ConfirmTaskBlock from '../ConfirmTaskBlock/ConfirmTaskBlock';
import PayTaskBlock from '../PayTaskBlock/PayTaskBlock';
import FinishedTaskInfo from '../FinishedTaskInfo/FinishedTaskInfo';
import SummaryBlock from '../SummaryBlock/SummaryBlock';
import { ConfirmTaskHandlerType, SolutionsType } from '../../types';
import {
  TASK_STATUS_CONFIRMED,
  TASK_STATUS_PROCESSED,
  TASK_STATUS_PAID
} from '../../appConstants';

export default function PaymentForm(props: IPaymentFormProp) {
  const { status, subtotal, currency, id, isConfirmButtonDisabled, confirmTaskHandler, confirmTaskErrorMessage, solutions, removedCodes, selectedCodes } = props;
  const [isSummary, setIsSummary] = useState(false);
  useEffect(() => {
    if (solutions.length || selectedCodes.length || removedCodes.length) {
      setIsSummary(true);
    }
  }, [solutions, selectedCodes, removedCodes]);
  return (
    <>
      {
        isSummary &&
        <SummaryBlock
          solutions={solutions}
          selectedCodes={selectedCodes}
          removedCodes={removedCodes}
          className='bottomSpace'
        />
      }
      { status === TASK_STATUS_PROCESSED && <ConfirmTaskBlock
        id={id}
        isDisabled={isConfirmButtonDisabled}
        confirmTask={confirmTaskHandler}
        errorMessage={confirmTaskErrorMessage}
      /> }
      { status === TASK_STATUS_CONFIRMED && <PayTaskBlock
          subtotal={subtotal}
          currency={currency}
          id={id}
      /> }
      { status === TASK_STATUS_PAID && <FinishedTaskInfo
        subtotal={subtotal}
        currency={currency}
        id={id}
      /> }
    </>
  )
}

interface IPaymentFormProp {
  status: string,
  subtotal: number,
  currency: string,
  id: number,
  isConfirmButtonDisabled: boolean,
  confirmTaskHandler: ConfirmTaskHandlerType,
  confirmTaskErrorMessage: string,
  solutions: SolutionsType,
  removedCodes: string,
  selectedCodes: string,
}