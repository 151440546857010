import React, { FC } from 'react';
import './AFLogo.scss';
import logo from './logo.svg';

interface AFLogoProps {}

const AFLogo: FC<AFLogoProps> = () => (
    <span className="AFLogo">
        <img className="AFLogo-icon" src={logo} alt="af logo"/>
    </span>
);

export default AFLogo;
