import './UserMenu.scss'
import React, { useState, MouseEvent } from 'react';
import Link from '@material-ui/core/Link';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  IconButton,
  Tooltip,
  MenuItem,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import {
  AccountCircleOutlined,
  ExitToAppOutlined,
  SettingsOutlined,
  AccountBalanceWalletOutlined,
} from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import getUserInstance from '../../model/user';
import { authState } from '../../recoil/atoms/authorize';
import parseErrors from '../../utils/parse-error-messages';
import ButtonErrorMessage from '../ButtonErrorMessage/ButtonErrorMessage';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import clearSettingsLogout from '../../utils/clear-settingns-logout';

const User = getUserInstance();
const LOGOUT_ERROR_KEY = 'logout-error-message';

const useStyles = makeStyles(() =>
  createStyles({
    settingsLink: {
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      }
    },
  }),
);

export default function UserMenu() {
  const { t, i18n } = useTranslation();
  const setAuthToken = useSetRecoilState(authState);
  const [logoutErrorMessage, setLogoutErrorMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  function logout() {
    User.logOut()
    .then(() => {
      setAuthToken('');
      window.location.href = '/';
    })
    .catch((e) => {
      const message = parseErrors(e, LOGOUT_ERROR_KEY, i18n.exists);
      setLogoutErrorMessage(message);
      if (message === `401-${LOGOUT_ERROR_KEY}`) {
        clearSettingsLogout();
      }
    });
  }
  return (
    <>
      <Tooltip
        title={t('account-settings')}
      >
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar>
            <AccountCircleOutlined/>
          </Avatar>
          { logoutErrorMessage ? 
            <div className="UserMenu-error-message">
              <ButtonErrorMessage
                text={logoutErrorMessage}
              />
            </div> : ''
          }
        </IconButton>
      </Tooltip>
      <DropdownMenu
        anchorEl={anchorEl}
        isOpen={open}
        closeMenu={handleClose}
      >
        <MenuItem>
          <Link href="/wallet" className={classes.settingsLink}>
            <ListItemIcon>
              <AccountBalanceWalletOutlined fontSize="small" />
            </ListItemIcon>
            {t('wallet-menu')}
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link href="/settings" className={classes.settingsLink}>
            <ListItemIcon>
              <SettingsOutlined fontSize="small" />
            </ListItemIcon>
            {t('account-settings-menu')}
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <button
            type="button"
            className="logout-button"
            onClick={logout}
          >
            <ListItemIcon>
              <ExitToAppOutlined fontSize="small" />
            </ListItemIcon>
            {t('logout')}
          </button>
        </MenuItem>
      </DropdownMenu>
    </>
  )
}
