import { ISolution, OptionsType, IInfo, GetOptionsParam } from '../../types';
import getUserInstance from '../../model/user';
import {
    TASK_STATUS_INVALID,
    TASK_STATUS_UNPROCESSABLE,
    TASK_STATUS_REJECTED,
} from '../../appConstants';

export type SetterInfoType = {
    name: string,
    paramName: string,
    valueSetter: React.Dispatch<React.SetStateAction<IInfo>>,
    optionsSetter: React.Dispatch<React.SetStateAction<OptionsType>>,
    value: IInfo,
};

const User = getUserInstance();

export default function setTaskState({ 
    taskInfo,
    setVehicleOptions,
    setMakeOptions,
    setModelOptions,
    setEngineOptions,
    setEcuOptions,
    setSelectedVehicle,
    setSelectedMake,
    setSelectedEngine,
    setSelectedEcu,
    setSelectedModel,
    setStatusName,
    setIsVerified,
    setEnabledSolutions,
    setDisabledSolutions,
    setRemovedCodes,
    setSubtotal,
    setCurrency,
    setSelectedCodes,
    setFirmwareProperties,
    setChosenSolutions,
    setNonStock,
 }) {
    const enabledSolutions:ISolution[] = [];
    const disabledSolutions:ISolution[] = [];
    const { status: { name: statusName }, vehicleInfo, solutions, removedDtcCodes, selectedDtcCodes, subtotal, firmwareProperties } = taskInfo;
    const { vehicleType, make, engine, ecu, model } = vehicleInfo;
    getSelectsOptions({
        statusName,
        setSelectedVehicle,
        setVehicleOptions,
        vehicleType,
        setMakeOptions,
        setSelectedMake,
        make,
        setModelOptions,
        setSelectedEngine,
        engine,
        setEcuOptions,
        setSelectedEcu,
        setSelectedModel,
        ecu,
        model,
        setEngineOptions,
    });
    setStatusName(statusName);
    const verified = !( statusName === undefined ||
        statusName === TASK_STATUS_INVALID ||
        statusName === TASK_STATUS_UNPROCESSABLE ||
        statusName === TASK_STATUS_REJECTED);
    setIsVerified(verified);
    if (solutions) {
        solutions.forEach((solution) => {
            if (solution.type === 'ENABLED') {
                const solutionCopy = { ...solution } as ISolution;
                if (!Object.hasOwn(solutionCopy, 'dependsOn')) {
                    solutionCopy.dependsOn = [];
                }
                enabledSolutions.push(solution);
            }
            if (solution.type === 'DISABLED') {
                const solutionDerived = { ...solution };
                solutionDerived.selected = true;
                disabledSolutions.push(solutionDerived);
            }
        })
        if (enabledSolutions.length) {
            setEnabledSolutions(enabledSolutions);
            const selected = enabledSolutions.filter(({ selected }) => selected === true);
            setChosenSolutions(selected);
        }
        if (disabledSolutions.length) {
            setDisabledSolutions(disabledSolutions);
        }
    }
    if (removedDtcCodes) {
        setRemovedCodes(removedDtcCodes.join(', '));
    }
    if (selectedDtcCodes) {
        setSelectedCodes(selectedDtcCodes.join(', '));
    }
    if (subtotal) {
        const { amount, currency } = subtotal;
        if (amount) {
            setSubtotal(amount);
        }
        if (currency) {
            setCurrency(currency);
        }
    }
    if (firmwareProperties && firmwareProperties.length) {
        setFirmwareProperties(firmwareProperties);
        const isNonStock = firmwareProperties.some(({ name, value }) => name === 'nonStockProcessable' && value);
        setNonStock(isNonStock);
    }
}

function getSelectsOptions({
    statusName,
    setSelectedVehicle,
    setVehicleOptions,
    vehicleType,
    setMakeOptions,
    setSelectedMake,
    make,
    setModelOptions,
    setSelectedEngine,
    engine,
    setEcuOptions,
    setSelectedEcu,
    setSelectedModel,
    ecu,
    model,
    setEngineOptions,
}) {
    const setterCollection = [
        {
            name: 'types',
            paramName: '',
            valueSetter: setSelectedVehicle,
            optionsSetter: setVehicleOptions,
            value: vehicleType,
        },
        {
            name: 'makes',
            paramName: 'vehicleTypeId',
            valueSetter: setSelectedMake,
            optionsSetter: setMakeOptions,
            value: make,
        },
        {
            name: 'models',
            paramName: 'makeId',
            valueSetter: setSelectedModel,
            optionsSetter: setModelOptions,
            value: model,
        },
        {
            name: 'engines',
            paramName: 'modelId',
            valueSetter: setSelectedEngine,
            optionsSetter: setEngineOptions,
            value: engine,
        },
        {
            name: 'ecus',
            paramName: 'engineId',
            valueSetter: setSelectedEcu,
            optionsSetter: setEcuOptions,
            value: ecu,
        },
    ];
    if (statusName === TASK_STATUS_INVALID) {
        getOptionsSuccessively(setterCollection);
    } else {
        setSelectedOptions(setterCollection);
    }
}

function getOptionsSuccessively(setterCollection) {
    function serializePromises(immediate) {
        let last = Promise.resolve('');
        return (setterInfo) => {
          last = last.then((id) => immediate(id, setterInfo));
          return last;
        }
    }

    async function serializeOptionsFetch(id: string, setterInfo: SetterInfoType) {
        const { name, paramName, valueSetter, optionsSetter, value } = setterInfo;
        const reqParam: GetOptionsParam = { name };
        if (paramName) {
            reqParam.param = {
                paramName,
                id,
            }
        }
        const data = await User.getOptions(reqParam);
        optionsSetter(data);
        valueSetter(value);
        return value.id;
    }

    const loadOptionsSafe = serializePromises(serializeOptionsFetch);
    setterCollection.forEach(item => loadOptionsSafe(item));
}

function setSelectedOptions(setterCollection) {
    setterCollection.forEach(({ valueSetter, optionsSetter, value }) => {
        valueSetter(value);
        optionsSetter([value]);
    });
}