import React, { FC } from 'react';
import { Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { IS_PROD, OTHER_PROD_HOST } from '../../appConstants';
import './ChangeDomain.scss';

interface ChangeDomainProps {}

const useStyles = makeStyles(() =>
  createStyles({
    changeDomainLink: {
      marginLeft: '5px',
    },
  }),
);

const ChangeDomain: FC<ChangeDomainProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const otherHost = IS_PROD ? OTHER_PROD_HOST : '/';
  return (
  <div className="ChangeDomain">
    <div className="ChangeDomain-text-wrapper">
      <Trans t={t} i18nKey="change-domain">
        <Link className={classes.changeDomainLink} href={`${otherHost}register`}/>
      </Trans>
    </div>
  </div>
)};

export default ChangeDomain;
