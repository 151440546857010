import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import PolicyList from '../../components/PolicyList/PolicyList';
import './PolicyPage.scss';

const PolicyPage: FC<IPolicyPageProps> = () => {
    const { t } = useTranslation();
    return (
    <>
        <OneColumnLayout>
            <Typography component="h2" variant="h6">{t('policy-title')}</Typography>
            <PolicyList/>
        </OneColumnLayout>
    </>
)}

interface IPolicyPageProps extends RouteComponentProps<{}> {}

export default PolicyPage;
