import React, { useEffect } from 'react';
import {
  CheckCircleOutlined,
  DescriptionOutlined,
  InfoOutlined,
  ErrorOutline,
  CancelOutlined,
  FlagOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import AFSelect from '../AFSelect/AFSelect';
import Alert from '../Alert/Alert';
import InfoBlock from '../InfoBlock/InfoBlock';
import SourceFileUploader from '../SourceFileUploader/SourceFileUploader';
import APIButton from '../APIButton/APIButton';
import getKey from '../../utils/getKey';
import {
  LAST_APIBUTTON_MARGIN,
  TASK_STATUS_INVALID,
  TASK_STATUS_UNPROCESSABLE,
  TASK_STATUS_REJECTED,
} from '../../appConstants';
import {
  IFirmwareProperty,
  IInfo,
  SelectHandlerType,
  IVerifyFormInputs,
  SelectNamesType
} from '../../types';
import './VerifyForm.scss';

type SelectType = {
  name: SelectNamesType,
  options: IInfo[],
  selectedValue: IInfo,
}

export default function VerifyForm({ status, selects, isVerified, firmwareProperties, selectHandler, formHandler, message, isLoader, setFileContent, fileName, fileSize, isNonStock }: IVerifyFormProp) {
  const { t } = useTranslation();
  const isSubmitDisabled = status === TASK_STATUS_UNPROCESSABLE || false;
  const submitText = status === TASK_STATUS_INVALID ? 're-validate' : 'validate';
  const isSubmitButton =  status === TASK_STATUS_INVALID || status === '' || status === undefined;
  const isNonStockProcessable = isVerified && isNonStock;
  const isSuccessfulValidation = isVerified && !isNonStock;
  const isNonStockRejected = status === TASK_STATUS_REJECTED;
  const isProcessingFailed = status === TASK_STATUS_UNPROCESSABLE;
  const isValidationFailed = status === TASK_STATUS_INVALID;
  const visibleProperties = firmwareProperties.filter(item => item.userVisible);
  const isVisibleProps = !!(visibleProperties.length && status);
  const isSourceFile = isVisibleProps || !!(fileName && fileSize);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IVerifyFormInputs>({
    defaultValues: {
      vehicle: '',
      make: '',
      model: '',
      engine: '',
      ecu: '',
      fileinput: '',
    }
  });

  useEffect(() => {
    selects.forEach((item) => {
      setValue(item.name, item.selectedValue.id);
    });
  }, [selects, setValue])

  return (
    <>
      <form className="VerifyForm" onSubmit={handleSubmit(formHandler)}>
        {
          selects.map(({ name, options, selectedValue }) => {
            const isDisabled = (status !== undefined &&
              status !== '' &&
              status !== TASK_STATUS_INVALID) ||
              options.length === 0;
            return (
            <div key={getKey()}>
              <AFSelect
                disabled={isDisabled}
                labelName={name}
                selectId={selectedValue.id}
                options={options}
                selectHandler={selectHandler}
                control={control}
                errors={errors}
              />
            </div>
          )})
        }
        {
          !status &&
            <>
              <InfoBlock
                  icon={<InfoOutlined/>}
                  title="file-upload-guidance"
                >
                  <p className="InfoBlock--content">{t('original-files')}</p>
                  <p className="InfoBlock--content">{t('unpack-files')}</p>
              </InfoBlock>
              <SourceFileUploader
                control={control}
                errors={errors}
                setFileContent={setFileContent}
              />
            </>
        }
        { isSourceFile && <div className="file-info">
          <InfoBlock
            icon={<DescriptionOutlined/>}
            title="source-file"
            isSeparateTitle={true}
          >
            <table className="file-info--list">
              <tbody>
                <tr className="file-info--item">
                  <td className="file-info--name">{t('file-name')}:</td>
                  <td className="file-info--value">{fileName}</td>
                </tr>
                <tr className="file-info--item">
                  <td className="file-info--name">{t('size')}:</td>
                  <td className="file-info--value">{fileSize}</td>
                </tr>
                {
                  isVisibleProps && visibleProperties.map((item) => {
                    if (item.userVisible) {
                      return (
                        <tr
                          className="file-info--item"
                          key={getKey()}
                        >
                          <td className="file-info--name">{`${item.name}:`}</td>
                          <td className="file-info--value">{`${item.value}`}</td>
                        </tr>);
                    }
                    return '';
                  })
                }
              </tbody>
            </table>
          </InfoBlock>
        </div>
        }
        {
          isValidationFailed &&
          <InfoBlock
            icon={<CancelOutlined/>}
            title="validation-failed"
            color="red"
          >
            <p className="InfoBlock--content">{t('validation-failed-text')}</p>
            <p className="InfoBlock--content">{t('validation-invitation')}</p>
          </InfoBlock>
        }
        {
          isProcessingFailed &&
          <InfoBlock
            icon={<FlagOutlined/>}
            title="unprocessable-file"
            color="red"
          >
            <p className="InfoBlock--content">{t('file-processing-failed')}</p>
          </InfoBlock>
        }
        {
          isNonStockRejected &&
          <InfoBlock
            icon={<CancelOutlined/>}
            color="red"
            title="nonstock"
          >
            <p className="InfoBlock--content">{t('nonstock-rejected')}</p>
          </InfoBlock>
        }
        {
          isSuccessfulValidation && 
          <Alert
            icon={<CheckCircleOutlined/>}
            color="success"
            text="successful-validation"
          />
        }
        {
          isNonStockProcessable && 
          <InfoBlock
            icon={<ErrorOutline/>}
            color="yellow"
            title="nonstock"
          >
            <p className="InfoBlock--content">{t('nonstock-processable')}</p>
          </InfoBlock>
        }
        {
          isSubmitButton &&
          <div>
            <APIButton
              isDisabled={isSubmitDisabled}
              buttonText={submitText}
              errorMessage={message}
              type="submit"
              isLoader={isLoader}
              styles={LAST_APIBUTTON_MARGIN}
            />
          </div>
        }
      </form>
    </>
  )
}

interface IVerifyFormProp {
  status: string,
  selects: SelectType[],
  isVerified: boolean,
  firmwareProperties: IFirmwareProperty[],
  selectHandler: SelectHandlerType,
  formHandler: (data: IVerifyFormInputs) => void,
  message: string,
  isLoader: boolean,
  setFileContent: React.Dispatch<React.SetStateAction<File>>,
  fileName: string,
  fileSize: number,
  isNonStock: boolean,
}