import React from 'react';
import { useRecoilValue } from 'recoil';
import PricesSolutionsCheckboxItem from './PricesSolutionsCheckboxItem';
import Title from '../Title/Title';
import { PriceSolutionsFieldType } from '../../types';
import getKey from '../../utils/getKey';
import { priceSolutionsState } from '../../recoil/atoms/price-solutions';
import './PricesSolutionsForm.scss';

interface IPricesSolutionsList {
  icon: React.ReactNode,
  disabled: boolean,
  title: string,
  control: PriceSolutionsFieldType,
}

export default function ProcessList({ icon, disabled, title, control }: IPricesSolutionsList) {
  const solutions = useRecoilValue(priceSolutionsState);
  return (
    <>
      <Title
        icon={icon}
        title={title}
      />
      { solutions.map(({ name, code, isSelected }) => (
        <PricesSolutionsCheckboxItem
          key={getKey()}
          name={name}
          code={code}
          disabled={disabled}
          control={control}
          isSelected={isSelected}
        />
      )) }
    </>
  )
}
