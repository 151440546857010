import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import './InvitationToRegistration.scss';

interface InvitationToRegistrationProps {
  text: string,
  href: string,
}

const InvitationToRegistration: FC<InvitationToRegistrationProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="register-invitation-container">
      <p>
        <Trans t={t} i18nKey={props.text}>
          <Link href={props.href}/>
        </Trans>
      </p>
    </div>
  )
};

export default InvitationToRegistration;
