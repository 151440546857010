import React from 'react';
import { MemoryOutlined, CancelOutlined, CheckCircleOutlined, WarningOutlined } from  '@material-ui/icons';
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from 'react-i18next';
import ProcessList from '../ProcessList/ProcessList';
import RemovalInputs from '../RemovalInputs/RemovalInputs';
import APIButton from '../APIButton/APIButton';
import Alert from '../Alert/Alert';
import InfoBlock from '../InfoBlock/InfoBlock';
import {
  SolutionsType,
  IProcessFormInputs,
  SolutionsDataType,
  SolutionsWarningType
} from '../../types';
import {
  LAST_APIBUTTON_MARGIN,
  TASK_STATUS_VERIFIED,
  TASK_STATUS_CONFIRMED,
  TASK_STATUS_PAID
} from '../../appConstants';
import './ProcessForm.scss';

export default function ProcessForm({ enabledSolutions, disabledSolutions, removedCodes, selectedCodes, status, submitFormHandler, errorMessage, isLoader, solutionsWarning }: IProcessFormProp) {
  const { t } = useTranslation();
  const isProcessed = status !== TASK_STATUS_VERIFIED;
  const isConfirmed = status === TASK_STATUS_CONFIRMED || status === TASK_STATUS_PAID;
  const defaultSolutions = {} as SolutionsDataType;
  const { name: checkbox, dependsOn } = solutionsWarning || {};
  const dependentList = dependsOn?.join(', ');
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<IProcessFormInputs>({
    defaultValues: {
      codes: selectedCodes,
      ...defaultSolutions,
    }
  });

  function setEmptyError() {
    const root = getValues();
    if (root) {
      const inputs = Object.keys(root);
      inputs.forEach(input => setError(input, { type: 'empty-form', message: 'empty-form' }))
    }
  }

  const formErrors = {
    'empty-inputs': setEmptyError,
  }

  return (
    <form className="ProcessForm" onSubmit={(e) => {
      handleSubmit(submitFormHandler)(e)
      .catch((e) => {
        const { message } = e;
        formErrors[message]();
      });
    }}>
      <div className="ProcessList">
        { enabledSolutions.length ? 
          <ProcessList
            icon={<MemoryOutlined/>}
            solutions={enabledSolutions}
            disabled={!!isConfirmed}
            title='processing-available'
            control={control}
            errors={errors}
            isUnavailable={false}
          /> : ''
        }
        {
          solutionsWarning &&
            <InfoBlock
              color="yellow"
              title="warning"
              icon={<WarningOutlined/>}
            >
              <p className="InfoBlock--content">
                <Trans
                  t={t}
                  i18nKey="solutions-warning"
                  dependentList={dependentList}
                  checkbox={checkbox}
                >
                  Correct operation of {{dependentList}} is not guaranteed when {{checkbox}} is disabled. You can leave these solutions intact if you’re fully aware of your actions. We recommend disabling them as well.
                </Trans>
              </p>
            </InfoBlock>
        }
        { disabledSolutions.length ? 
          <ProcessList
            icon={<CancelOutlined/>}
            solutions={disabledSolutions}
            disabled={true}
            title='processing-disable'
            control={control}
            errors={errors}
            isUnavailable={true}
          /> : ''
        }
      </div>
      <RemovalInputs
        removedCodes={removedCodes}
        control={control}
        errors={errors}
      />
      {
        isProcessed && 
        <div className="alert-wrapper">
          <Alert
            icon={<CheckCircleOutlined/>}
            color="success"
            text="successful-processing"
          />
        </div>
      }
      <APIButton
        isDisabled={false}
        buttonText={isProcessed ? 're-process' : 'process'}
        errorMessage={errorMessage}
        type="submit"
        isLoader={isLoader}
        styles={LAST_APIBUTTON_MARGIN}
      />
    </form>
  )
}

interface IProcessFormProp {
  enabledSolutions: SolutionsType,
  disabledSolutions: SolutionsType,
  removedCodes: string,
  selectedCodes: string,
  status: string,
  submitFormHandler: (data: IProcessFormInputs) => void,
  errorMessage: string,
  isLoader: boolean,
  solutionsWarning: SolutionsWarningType,
}