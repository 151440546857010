import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { authState } from '../../recoil/atoms/authorize';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import AuthorizeUser from '../../components/AuthorizeUser/AuthorizeUser';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';
import InvitationToRegistration from '../../components/InvitationToRegistration/InvitationToRegistration';
import './LoginPage.scss';

const LoginPage: FC<ILoginPageProps> = () => {
    const isAuthorized = useRecoilValue(authState);
    useEffect(() => {
        if (isAuthorized) {
            window.location.href = '/tasks';
        }
    })

    return (
        <>
            { !isAuthorized && 
                <OneColumnLayout>
                    <DefaultGreeting/>
                    <AuthorizeUser/>
                    <InvitationToRegistration
                        text="register-invitation"
                        href="/register"
                    />
                </OneColumnLayout>
            }
        </>
    );
}

interface ILoginPageProps extends RouteComponentProps<{}> {}

export default LoginPage;
