import {
    IS_PROD,
    DEFAULT_PROD_CAPTCHA_KEY,
    DEFAULT_DEV_CAPTCHA_KEY,
    CAPTCHA_KEY,
} from "../../appConstants";

export default function getCaptchaKey() {
    let res = '';
    if (CAPTCHA_KEY) {
        res = CAPTCHA_KEY;
    } else if (IS_PROD) {
        res = DEFAULT_PROD_CAPTCHA_KEY;
    } else {
        res = DEFAULT_DEV_CAPTCHA_KEY;
    }
    return res;
}