import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InlineStylesType } from '../../types';
import './Alert.scss';

interface AlertProps {
  icon: React.ReactNode,
  color: string,
  text: string,
  isMarginBottom?: boolean,
  style?: InlineStylesType | null,
  isFitContent?: boolean,
}

const Alert: FC<AlertProps> = ({ icon, color, text, isMarginBottom, style, isFitContent }) => {
  const { t } = useTranslation();
  return (
    <>
      { style ? 
        <div
          className={`Alert Alert--${color}${isMarginBottom ? ' Alert--margin-bottom' : ''}${isFitContent ? ' Alert--fit-content' : ''}`}
          style={style}
        >
          <span className="Alert-icon">
            {icon}
          </span>
          {t(text)}
        </div> :
        <div
          className={`Alert Alert--${color}${isMarginBottom ? ' Alert--margin-bottom' : ''}${isFitContent ? ' Alert--fit-content' : ''}`}
        >
          <span className="Alert-icon">
            {icon}
          </span>
          {t(text)}
        </div>
      }
    </>
)};

Alert.defaultProps = {
  isMarginBottom: false,
  style: null,
  isFitContent: false,
}

export default Alert;
