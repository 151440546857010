import React, { FC, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { authState } from '../../recoil/atoms/authorize';
import TotalTitle from '../TotalTitle/TotalTitle';
import APIButton from '../APIButton/APIButton';
import capitaliseString from '../../utils/capitalise-string';
import parseErrors from '../../utils/parse-error-messages';
import getKey from '../../utils/getKey';
import getUserInstance, { ProviderType } from '../../model/user';
import { LAST_APIBUTTON_MARGIN } from '../../appConstants';
import './PayTaskBlock.scss';

interface PayTaskBlockProps {
  subtotal: number,
  currency: string,
  id: number,
}

const User = getUserInstance();
const PAYMENT_ERROR_KEY = 'payment-error';

const PayTaskBlock: FC<PayTaskBlockProps> = ({ subtotal, currency, id }) => {
  const { i18n } = useTranslation();
  const [buttonError, setButtonError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disabledButton, setDisabledButton] = useState('');
  const [providers, setProviders] = useState<ProviderType[] | null>(null);

  useEffect(() => {
    if (id) {
      User.getProviders()
      .then((data: ProviderType[]) => {
        setProviders(data)
      });
    }
  }, [id]);

  function getPaymentUrl({ id, provider, token, customHandling }) {
    setErrorMessage('');
    setDisabledButton(provider);
    User.openPaymentPage({ id, provider, token })
    .then((url: string) => {
      if (url) {
        if (customHandling) {
          return User.handleWalletPaymentUrl(url)
          .then(() => {
            window.location.reload();
          });
        }
        window.location.href = url;
      }
      return Promise.resolve();
    })
    .catch((e) => {
      setButtonError(provider);
      const message = parseErrors(e, PAYMENT_ERROR_KEY, i18n.exists);
      setErrorMessage(message);
    })
    .finally(() => {
      setDisabledButton('');
    });
  }

  const token = useRecoilValue(authState);
  return (
    <div className="PayTaskBlock">
      <TotalTitle
        subtotal={subtotal}
        currency={currency}
      />
      { providers?.map((provider, index) => {
        const { name, customHandling } = provider;
        const isLast = index === providers.length - 1;
        const styles = isLast ? LAST_APIBUTTON_MARGIN : {};
        return (
          <APIButton
            key={getKey()}
            isDisabled={disabledButton === name}
            isLoader={disabledButton === name}
            handler={getPaymentUrl.bind(null, { id, provider: name, token, customHandling })}
            buttonText={capitaliseString(name)}
            errorMessage={buttonError === name ? errorMessage : ''}
            styles={styles}
          />
        )
      }) }
    </div>
)};

export default PayTaskBlock;
