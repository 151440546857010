import React, { FC, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { authState } from '../../recoil/atoms/authorize';
import AuthForm from "../AuthForm/AuthForm";
import getUserInstance from '../../model/user';
import { FormDataType } from '../../types';
import parseErrors from '../../utils/parse-error-messages';
import './AuthorizeUser.scss';

const User = getUserInstance();
const AUTHORIZE_ERROR_MESSAGE = 'register-error-message';

const AuthorizeUser: FC<{}> = () => {
  const { i18n } = useTranslation();
  const setAuthToken = useSetRecoilState(authState);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableForm, setDisableForm] = useState(false);

  async function authorize(data: FormDataType) {
    const { username, password } = data;
    setDisableForm(true);
    const token = await window.grecaptcha.getResponse();
    if (!token) {
      setErrorMessage('captcha-failed');
      return;
    }
    User.authorize({ username, password, token })
    .then((data) => {
      setDisableForm(false);
      setAuthToken(data);
    })
    .catch(e => {
      const message = parseErrors(e, AUTHORIZE_ERROR_MESSAGE, i18n.exists);
      setErrorMessage(message);
    })
  }

  function clearErrorMessage() {
    setDisableForm(false);
    setErrorMessage('');
  }

  return (
    <AuthForm
      formHandler={authorize}
      isRegistration={false}
      errorMessage={errorMessage}
      blurHandler={clearErrorMessage}
      disable={disableForm}
    />
  )
};

export default AuthorizeUser;
