import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Tooltip,
  MenuItem,
} from '@material-ui/core';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { langState } from '../../recoil/atoms/lang';
import getKey from '../../utils/getKey';
import { LANGUAGES } from '../../appConstants';
import setSettingsCookie from '../../utils/setSettingsCookie';

const useStyles = makeStyles(() =>
  createStyles({
    dropdownItem: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '&:last-child': {
        borderBottom: 'none',
      }
    },
  }),
);

export default function LanguagePicker(/* props: ILanguagePickerProp */) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lang, setGlobalLang] = useRecoilState(langState);
  const [currentLang, setCurrentLang] = useState(lang);
  const isOpen = Boolean(anchorEl);
  const classes = useStyles();
  function closeMenu() {
    setAnchorEl(null);
  }
  function openLanguagePicker(e) {
    setAnchorEl(e.currentTarget);
  }
  function changeLanguage(lang) {
    i18n.changeLanguage(lang, () => {
      setCurrentLang(lang);
      setGlobalLang(lang);
      setSettingsCookie('lang', lang);
    });
    closeMenu();
  }
  return (
    <>
      <Tooltip
        title={t('language-picker')}
      >
        <IconButton
          onClick={openLanguagePicker}
        >
          <LanguageOutlinedIcon/>
        </IconButton>
      </Tooltip>
      <DropdownMenu
        isOpen={isOpen}
        anchorEl={anchorEl}
        closeMenu={closeMenu}
      >
        {LANGUAGES.map(({ code, name }) => (
          <MenuItem
            disabled={currentLang === code}
            className={classes.dropdownItem}
            onClick={() => {
              changeLanguage(code)
            }}
            key={getKey()}
          >
            {name}
          </MenuItem>
        ))}
      </DropdownMenu>
      {currentLang.toUpperCase()}
    </>
  )
}

interface ILanguagePickerProp {
  // TODO
}