import React, { FC } from 'react';
import Title from '../Title/Title';
import './InfoBlock.scss';

interface InfoBlockProps {
  icon?: React.ReactNode | null,
  title?: string,
  color?: string,
  isSeparateTitle?: boolean,
  className?: string,
}

const InfoBlock: FC<InfoBlockProps> = ({ icon, title, color, children, isSeparateTitle, className }) => {
  const infoBlockBgColor = color ? ` InfoBlock__${color}` : '';
  const additionalClasses = className ? ` ${className}` : '';
  const innerTopPadding = isSeparateTitle ? '' : ' InfoBlock__smaller-padding';
  const blockClass = `InfoBlock${infoBlockBgColor}${innerTopPadding}${additionalClasses}`;
  return (
  <>
    {
      isSeparateTitle && title && <div className="InfoBlock--title">
        <Title icon={icon} title={title} />
      </div>
    }
    <div className={blockClass}>
      {
        !isSeparateTitle && title && <div className="InfoBlock--title InfoBlock--title__inside">
          <Title icon={icon} title={title} styles={{ marginTop: '0' }} />
        </div>
      }
      {children}
    </div>
  </>
)};

InfoBlock.defaultProps = {
  icon: null,
  color: '',
  isSeparateTitle: false,
  className: '',
  title: '',
}

export default InfoBlock;
