// src/AppRouter.tsx

import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Task from './pages/Task/Task';
import TasksPage from './pages/TasksPage/TasksPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import AccountSettingsPage from './pages/AccountSettingsPage/AccountSettingsPage';
import MainPage from './pages/MainPage/MainPage';
import LoginPage from './pages/LoginPage/LoginPage';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import VerifyEmailPage from './pages/VerifyEmailPage/VerifyEmailPage';
import PolicyPage from './pages/PolicyPage/PolicyPage';
import WalletPage from './pages/WalletPage/WalletPage';
import PricesPage from './pages/Prices/Prices';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import './i18n/config';

const AppRouter = () => (
  <Router>
    <RecoilRoot>
      <Suspense fallback={<span>Loading...</span>}>
        <NavBar/>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/tasks" component={TasksPage} />
          <Route exact path="/create-task" component={Task} />
          <Route exact path="/task/:id" component={Task} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/contacts" component={ContactsPage} />
          <Route exact path="/settings" component={AccountSettingsPage} />
          <Route exact path="/email-confirmation" component={VerifyEmailPage} />
          <Route exact path="/policy" component={PolicyPage} />
          <Route exact path="/wallet" component={WalletPage} />
          <Route exact path="/prices" component={PricesPage} />
          <Route exact path="*" component={PageNotFound} />
        </Switch>
        <Footer/>
      </Suspense>
    </RecoilRoot>
  </Router>
)

export default AppRouter
