import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import reverseString from '../../utils/reverse-string';
import { PROD_HOST_NAME } from '../../appConstants';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import './ContactsPage.scss';

const ContactsPage: FC<IContactsPageProps> = () => {
    const { t } = useTranslation();
    const mailAddress = `support@${PROD_HOST_NAME}`;
    const reversedMailAddress = reverseString(mailAddress);
    return (
        <OneColumnLayout>
            <p>{t('invitation-to-contact')}:</p>
            <ul>
                <li>
                    <span style={{ unicodeBidi: 'bidi-override', direction: 'rtl' }}>{reversedMailAddress}</span>
                </li>
            </ul>
            <p>
                <Trans t={t} i18nKey='entrepreneur-contacts'>
                    ИП Мотренко Игорь Валерьевич
                    <br/>
                    ИНН 614105652050
                    <br/>
                    ОГРНИП 312618123300038
                </Trans>
            </p>
        </OneColumnLayout>
    );
}

interface IContactsPageProps extends RouteComponentProps<{}> {}

export default ContactsPage;
