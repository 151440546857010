import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Typography from '@material-ui/core/Typography';
import { authState } from '../../recoil/atoms/authorize';
import { domChange } from '../../recoil/atoms/dom-change';
import { PROD_HOST_NAME } from '../../appConstants';
import getUserInstance from '../../model/user';
import parseErrors from '../../utils/parse-error-messages';
import reverseString from '../../utils/reverse-string';
import clearSettingsLogout from '../../utils/clear-settingns-logout';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import Loader from '../../components/Loader/Loader';
import AuthorizeUser from '../../components/AuthorizeUser/AuthorizeUser';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';
import InvitationToRegistration from '../../components/InvitationToRegistration/InvitationToRegistration';

import './WalletPage.scss';

const WALLET_BALLANCE_ERROR_KEY = 'get-wallet-balance-error-message';

const User = getUserInstance();

const WalletPage: FC<IWalletPageProps> = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userBalance, setUserBalance] = useState('');
  const setIsDomChange = useSetRecoilState(domChange);
  const authToken = useRecoilValue(authState);
  const { t, i18n } = useTranslation();

  const mailAddress = `support@${PROD_HOST_NAME}`;
  const reversedMailAddress = reverseString(mailAddress);

  useEffect(() => {
    if (authToken) {
      setIsLoader(true);
      User.getWalletBalance()
      .then(({ balance }: { balance: string }) => {
        setUserBalance(balance)
      })
      .catch((e) => {
        const message = parseErrors(e, WALLET_BALLANCE_ERROR_KEY, i18n.exists);
        setErrorMessage(message);
        if (message === `401-${WALLET_BALLANCE_ERROR_KEY}`) {
          clearSettingsLogout();
        }
      })
      .finally(() => setIsLoader(false))
      .then(() => setIsDomChange(true));
    }
  }, [authToken, setIsDomChange, i18n, setUserBalance]);
  if (!authToken) {
    return (
      <>
        <OneColumnLayout>
          <DefaultGreeting/>
          <AuthorizeUser/>
          <InvitationToRegistration
            text="register-invitation"
            href="/register"
          />
      </OneColumnLayout>
      </>
    );
  }
  if (isLoader) {
    return (
      <>
        <OneColumnLayout>
          <Loader size="large" isWrapper={true} />
        </OneColumnLayout>
      </>
    );
  }
  if (errorMessage) {
    return (
      <>
        <OneColumnLayout>
          <p>{t(errorMessage)}</p>
        </OneColumnLayout>
      </>
    );
  }
  return (
    <>
      <OneColumnLayout>
        <Typography paragraph>{t('wallet-content-balance', { userBalance })}</Typography>
        <Typography paragraph>
          <Trans t={t} i18nKey='wallet-content-contact'>
            <span style={{ unicodeBidi: 'bidi-override', direction: 'rtl' }}>{{ reversedMailAddress }}</span>
          </Trans>
        </Typography>
      </OneColumnLayout>
    </>
  );
}

interface IWalletPageProps extends RouteComponentProps<{}> {}

export default WalletPage;
