import React, { FC } from 'react';
import { Payment } from '@material-ui/icons';
import Title from '../Title/Title';
import getCurrencySign from '../../utils/getCurrencySign';
import './TotalTitle.scss';

interface TotalTitleProps {
  currency?: string,
  subtotal: number | string,
}


const TotalTitle: FC<TotalTitleProps> = ({ currency, subtotal }) => (
  <div className="TotalTitle">
    <Title
      icon={<Payment/>}
      title='subtotal-amount'
    />
    { currency ? ` ${getCurrencySign(currency)}${subtotal}` : subtotal }
  </div>
);

TotalTitle.defaultProps = {
  currency: '',
}

export default TotalTitle;
