import React, { FC, useState } from 'react';
import { CheckCircleOutlined } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { authState } from '../../recoil/atoms/authorize';
import { LAST_APIBUTTON_MARGIN } from '../../appConstants';
import getUserInstance from '../../model/user';
import TotalTitle from '../TotalTitle/TotalTitle';
import APIButton from '../APIButton/APIButton';
import Alert from '../Alert/Alert';
import './FinishedTaskInfo.scss';

interface FinishedTaskInfoProps {
  subtotal: number,
  currency: string,
  id: number,
}

const User = getUserInstance();

const FinishedTaskInfo: FC<FinishedTaskInfoProps> = ({ currency, subtotal, id }) => {
  const authToken = useRecoilValue(authState);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  function downloadFile({ id, authToken }) {
    setIsDisabled(true);
    User.downloadTaskFile(id, authToken)
    .catch(() => {
      setErrorMessage('download-error');
    })
    .finally(() => setIsDisabled(false));
  }

  return (
  <div className="FinishedTaskInfo">
    <TotalTitle
      subtotal={subtotal}
      currency={currency}
    />
    <div className="FinishedTask-alert-wrapper">
      <Alert
        icon={<CheckCircleOutlined/>}
        color='success'
        text='successful-payment'
      />
    </div>
    <APIButton
      isDisabled={isDisabled}
      isLoader={isDisabled}
      buttonText='download-file'
      handler={downloadFile.bind(null, { id, authToken })}
      errorMessage={errorMessage}
      styles={LAST_APIBUTTON_MARGIN}
    />
  </div>
)};

export default FinishedTaskInfo;
