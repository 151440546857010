import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './ButtonErrorMessage.scss';

interface ButtonErrorMessageProps {
  text: string,
}

const ButtonErrorMessage: FC<ButtonErrorMessageProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="ButtonError">{t(text)}</div>
  )
};

export default ButtonErrorMessage;
