import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      transform: "rotate(90deg)",
    },
  }),
);

export default function SummaryIcon() {
  const classes = useStyles();
  return (
    <DoubleArrowOutlinedIcon
      className={classes.button}
    />
  )
}
