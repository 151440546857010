import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import getKey from '../../utils/getKey';
import {
  IInfo,
  SelectHandlerType,
  VerifyFormFieldType,
  SelectNamesType,
  VerifyFormErrorsType
} from '../../types';
import './AFSelect.scss';

interface IAFSelectProp {
  disabled?: boolean,
  labelName: SelectNamesType,
  selectId: string,
  options: IInfo[],
  selectHandler: SelectHandlerType,
  control: VerifyFormFieldType,
  errors: VerifyFormErrorsType
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      maxHeight: 40,
    },
  }),
);

export default function AFSelect({
    labelName,
    selectId,
    options,
    disabled,
    selectHandler,
    control,
    errors,
  }: IAFSelectProp) {
  const { t } = useTranslation();
  const classes = useStyles();
  const stringId = selectId.toString();
  return (
    <>
        <InputLabel id={labelName}>{t(labelName)}</InputLabel>
        <Controller
          control={control}
          name={labelName}
          rules={{ required: `${labelName}-required` }}
          render={({ field: { onChange, name } }) => {
            const errorMessage = errors[name]?.message;
            return (
              <>
                <Select
                  disabled={disabled}
                  displayEmpty
                  fullWidth
                  className={classes.select}
                  labelId={name}
                  id={stringId}
                  value={stringId}
                  label={name}
                  name={name}
                  variant="outlined"
                  onChange={(e) => {
                    onChange(e.target.value);
                    selectHandler(e);
                  }}
                  error={!!(errorMessage)}
                >
                  <MenuItem
                    disabled
                    value=""
                  >
                    <em>{options.length ? t(`choose-${name}`) : ''}</em>
                  </MenuItem>
                  { options.length && options.map(item => (
                      <MenuItem
                        value={item.id.toString()}
                        key={getKey()}
                      >
                        {item.name}
                      </MenuItem>
                    )) }
                </Select>
                {errorMessage && <p className="select-error">{t(errorMessage)}</p>}
              </>
            )
          }}
        />
    </>
  )
}

AFSelect.defaultProps = {
  disabled: false,
}
