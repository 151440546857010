import React from 'react';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { useTranslation } from 'react-i18next';
import { BLACK_TEXT_COLOR } from '../../appConstants';
import AFButton from '../AFButton/AFButton';


export default function TasksButton(/* props: ITasksButtonProp */) {
  const { t } = useTranslation();
  return (
    <AFButton
      variant="text"
      startIcon={<AssignmentOutlinedIcon/>}
      href="/tasks"
      textColor={BLACK_TEXT_COLOR}
    >
      {t('my-tasks')}
    </AFButton>
  )
}

interface ITasksButtonProp {
  // TODO
}