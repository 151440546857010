import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import './DefaultGreeting.scss';

interface DefaultGreeting {
  greetingText?: string,
}

const DefaultGreeting: FC<DefaultGreeting> = ({ greetingText = 'default-greeting' }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="default-greeting">
        <Typography variant="h4" component="h3">{t(greetingText)}</Typography>
      </div>
    </>
  );
}

DefaultGreeting.defaultProps = {
  greetingText: 'default-greeting',
};

export default DefaultGreeting;
