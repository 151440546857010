import React, { FC, useState, useEffect } from 'react';
import { TextField, Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import BasicButton from '../BasicButton/BasicButton';
import { FormHandlerType, FormDataType } from '../../types';
import { EMAIL_REGEX } from '../../appConstants';
import getKey from '../../utils/getKey';
import getUserInstance from '../../model/user';
import ChangeDomain from '../ChangeDomain/ChangeDomain';
import WarningAboutTermsOfUse from '../WarningAboutTermsOfUse/WarningAboutTermsOfUse';
import CaptchaCheckbox from '../CaptchaCheckbox/CaptchaCheckbox';
import './AuthForm.scss';

interface AuthFormProps {
  formHandler: FormHandlerType,
  isRegistration: boolean,
  errorMessage: string,
  blurHandler: () => void,
  disable: boolean,
}

type CountryType = {
  code: string,
  name: string,
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      maxHeight: 40,
    },
    checkBox: {
      display: 'flex',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    }
  }),
);

const User = getUserInstance();

const AuthForm: FC<AuthFormProps> = ({ formHandler, blurHandler, isRegistration, errorMessage, disable }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormDataType>({
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
      country: '',
      marketingConsent: true,
    }
  });
  const ACTION = isRegistration ? 'REGISTER' : 'LOGIN';
  const submitBtnText = isRegistration ? 'register' : 'log-in';
  const classes = useStyles();
  const { t } = useTranslation();
  const [ countries, setCountries ] = useState<CountryType[]>([]);
  const [ isCountryDisable, setIsCountryDisable ] = useState(false);

  useEffect(() => {
    if (isRegistration) {
      User.getCountries()
      .then((data: CountryType[]) => {
        setCountries(data);
      })
      .catch(() => {
        setIsCountryDisable(true);
      })
    }
  }, [isRegistration]);

  return (
    <>
        <div className="AuthFormWrapper">
          <form
            className="AuthForm"
            onSubmit={handleSubmit(formHandler)}
          >
            <Controller
                control={control}
                name="username"
                rules={{
                  required: 'email-required-error-message',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "incorrect-email-error-message"
                  }
                }}
                render={({
                    field: { onChange, name, value }
                }) => (
                    <TextField
                        name={name}
                        label={t('email')}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={value}
                        placeholder={t('input-email')}
                        helperText={ errors.username && errors.username.message && t(errors.username.message) }
                        error={ !!(errors.username?.message || errorMessage) }
                        onBlur={blurHandler}
                    />
                )}
            />
            <Controller
                control={control}
                name="password"
                rules={{ 
                  required: 'password-required-error-message',
                  minLength: {
                    value: 4,
                    message: 'password-min-length-error-message'
                  }
                }}
                render={({
                    field: { onChange, name, value }
                }) => (
                    <TextField
                        name={name}
                        type="password"
                        label={t('password')}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        placeholder={t('input-password')}
                        onChange={onChange}
                        value={value}
                        helperText={ errors.password && errors.password.message && t(errors.password.message) }
                        error={ !!(errors.password?.message || errorMessage) }
                        onBlur={blurHandler}
                    />
                )}
            />
            { isRegistration && 
                <>
                <Controller
                    control={control}
                    name="confirmPassword"
                    rules={{
                      required: 'confirm-password-required-error-message',
                      minLength: {
                        value: 4,
                        message: 'password-min-length-error-message'
                      },
                      validate: (val) => {
                        const password = getValues('password');
                        return val === password || 'incorrect-repeat-password-error-message';
                      }
                    }}
                    render={({
                        field: { onChange, name, value }
                    }) => (
                        <TextField
                            name={name}
                            type="password"
                            label={t('confirm-password')}
                            margin="dense"
                            placeholder={t('confirm-input-password')}
                            fullWidth
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            helperText={ errors.confirmPassword && errors.confirmPassword.message && t(errors.confirmPassword.message) }
                            error={ !!(errors.confirmPassword?.message || errorMessage) }
                            onBlur={blurHandler}
                        />
                    )}
                />
                <Controller
                  control={control}
                  name="country"
                  rules={{ required: 'country-required-error-message' }}
                  render={({ field: { onChange, name, value } }) => (
                    <>
                      <Select
                        className={classes.select}
                        fullWidth
                        displayEmpty
                        labelId="country"
                        value={value}
                        label="country"
                        name="country"
                        variant="outlined"
                        onChange={onChange}
                        error={!!(errorMessage)}
                        disabled={isCountryDisable}
                        placeholder={t('choose-country')}
                      >
                        <MenuItem disabled value="">
                          <em>{t('choose-country')}</em>
                        </MenuItem>
                        { countries.length && countries.map(item => (
                            <MenuItem
                              value={item.code}
                              key={getKey()}
                            >
                              {item.name}
                            </MenuItem>
                          ))
                        }
                      </Select>

                    </>
                  )}
                />
                <Controller
                  control={control}
                  name='marketingConsent'
                  defaultValue={true}
                  render={({ field: { onChange, name, value } }) => (
                    <>
                      <FormControlLabel
                        className={classes.checkBox}
                        control={<Checkbox
                          checked={value}
                          value={value}
                          onChange={onChange}
                          inputProps={{ 'aria-label': name }}
                          color="primary"
                        />}
                        label={t('marketing-consent')}
                      />
                    </>
                  )}
                />
                <ChangeDomain/>
                <WarningAboutTermsOfUse/>
                </>
            }
            <CaptchaCheckbox action={ACTION}/>
            <div className="submit-wrap">
              <BasicButton
                type="submit"
                text={t(submitBtnText)}
                disabled={!!disable}
                fullWidth
              />
            </div>
            { errorMessage && <p>{t(errorMessage)}</p> }
          </form>
        </div>
    </>
  );
}

export default AuthForm;
