import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import AFSelect from '../AFSelect/AFSelect';
import APIButton from '../APIButton/APIButton';
import getKey from '../../utils/getKey';
import {
  IInfo,
  SelectHandlerType,
  IVerifyFormInputs,
  SelectNamesType
} from '../../types';
import { LAST_APIBUTTON_MARGIN } from '../../appConstants';
import './PricesSelectsForm.scss';

type SelectType = {
  name: SelectNamesType,
  options: IInfo[],
  selectedValue: IInfo,
}

export default function PricesSelectsForm({
  selects,
  selectHandler,
  formHandler,
  isDisabledButton,
  isLoader,
}: IPricesSelectsFormProp) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IVerifyFormInputs>({
    defaultValues: {
      vehicle: '',
      make: '',
      model: '',
      engine: '',
      ecu: '',
      fileinput: '',
    }
  });

  useEffect(() => {
    selects.forEach((item) => {
      setValue(item.name, item.selectedValue.id);
    });
  }, [selects, setValue])

  return (
    <>
      <form className="PricesSelectsForm" onSubmit={handleSubmit(formHandler)}>
        {
          selects.map(({ name, options, selectedValue }) => {
            const isDisabled = options.length === 0;
            return (
            <div key={getKey()}>
              <AFSelect
                disabled={isDisabled}
                labelName={name}
                selectId={selectedValue.id}
                options={options}
                selectHandler={selectHandler}
                control={control}
                errors={errors}
              />
            </div>
          )})
        }
        <APIButton
          isDisabled={isDisabledButton}
          buttonText='getSolutions'
          type="submit"
          isLoader={isLoader}
          styles={LAST_APIBUTTON_MARGIN}
        />
      </form>
    </>
  )
}

interface IPricesSelectsFormProp {
  selects: SelectType[],
  selectHandler: SelectHandlerType,
  formHandler: (data: IVerifyFormInputs) => void,
  isDisabledButton: boolean,
  isLoader: boolean,
}
