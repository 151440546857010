import React, { FC } from 'react';
import TotalTitle from '../TotalTitle/TotalTitle';

interface PricesTotalProps {
  subtotal: string,
  currency: string,
}

const PricesTotal: FC<PricesTotalProps> = ({ subtotal, currency }) => (
  <div className="PricesTotal">
    <TotalTitle
      subtotal={subtotal}
      currency={currency}
    />
  </div>
);

export default PricesTotal;
