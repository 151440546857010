import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { InlineStylesType } from '../../types';
import './Title.scss';

interface TitleProps {
  icon?: React.ReactNode | null,
  title: string,
  styles?: InlineStylesType,
}

const Title: FC<TitleProps> = ({ icon, title, styles }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="subtitle1" className="title">
      {Boolean(icon) && <span className="title-icon">{icon}</span>}
      {t(title)}
    </Typography>
  );
};

Title.defaultProps = {
  icon: null,
  styles: {},
}

export default Title;
