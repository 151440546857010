const currencySigns = {
    USD: '$',
    RUB: '₽',
    PLN: 'zł',
    EUR: '€',
};

export default function getCurrencySign(code: string) {
    return currencySigns[code] || `${code} `;
}
