import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Controller } from "react-hook-form";
import { PriceSolutionsFieldType } from '../../types';
import { priceSolutionsState } from '../../recoil/atoms/price-solutions';
import './PricesSolutionsForm.scss';

export interface PricesSolutionsItemProps {
  name: string,
  code: string,
  disabled: boolean,
  control: PriceSolutionsFieldType,
  isSelected: boolean,
}

const PricesSolutionsItem: FC<PricesSolutionsItemProps> = ({
  name: nameCheckbox,
  disabled,
  control,
  code,
  isSelected,
}) => {
  const [solutions, setSolutions] = useRecoilState(priceSolutionsState);
  return (
  <div className="PricesSolutionsItem">
    <Controller
      control={control}
      name={code}
      defaultValue={isSelected}
      render={({ field: { onChange, name } }) => (
        <>
          <FormControlLabel
            control={<Checkbox
              value={code}
              onChange={() => {
                let isChecked = isSelected;
                const updatedSolutions = solutions.map((item) => {
                  if (item.code === name) {
                    isChecked = !item.isSelected;
                    return {
                      name: nameCheckbox,
                      code,
                      isSelected: isChecked,
                    };
                  }
                  return item;
                });
                setSolutions(updatedSolutions);
                onChange(isChecked);
              }}
              inputProps={{ 'aria-label': code }}
              color="primary"
            />}
            label={nameCheckbox}
            checked={isSelected}
            disabled={disabled}
          />
        </>
      )}
    />
  </div>
)};

export default PricesSolutionsItem;
