import React, { FC } from 'react';
import { ConfirmTaskHandlerType } from '../../types';
import APIButton from '../APIButton/APIButton';
import { LAST_APIBUTTON_MARGIN } from '../../appConstants';
import './ConfirmTaskBlock.scss';

interface ConfirmTaskBlockProps {
  id: number,
  confirmTask: ConfirmTaskHandlerType;
  isDisabled: boolean,
  errorMessage: string,
}

const ConfirmTaskBlock: FC<ConfirmTaskBlockProps> = ({ id, confirmTask, isDisabled, errorMessage }) => {
  const buttonHandler = confirmTask.bind(null, id);
  return (
  <div className="ConfirmTaskBlock">
    <APIButton
      isDisabled={isDisabled}
      handler={buttonHandler}
      buttonText='complete-processing'
      errorMessage={errorMessage}
      isLoader={isDisabled}
      styles={LAST_APIBUTTON_MARGIN}
    />
  </div>)
};

export default ConfirmTaskBlock;
