import Cookies from 'js-cookie';
import {
    authCookie,
    langCookie,
    currencyCookie,
    countryCookie,
} from '../appConstants';

export default function clearSettingsLogout() {
    Cookies.remove(authCookie);
    Cookies.remove(langCookie);
    Cookies.remove(currencyCookie);
    Cookies.remove(countryCookie);
    window.location.href = '/';
}
