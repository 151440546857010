import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import { langState } from '../../recoil/atoms/lang';
import { POLICY_CONFIG } from '../../appConstants';
import './PolicyList.scss';

interface PolicyListProps {}

const PolicyList: FC<PolicyListProps> = () => {
  const lang = useRecoilValue(langState);
  const policyList = POLICY_CONFIG[lang];
  const filteredPolicyList = policyList.filter(({ domain }) => domain === process.env.REACT_APP_HOST || !domain);
  const { t } = useTranslation();
  return (
    <ul className="PolicyList">
      {filteredPolicyList.map(({ key, link }) => (
        <li key={link}>
          <Link href={link} target="_blank"> {t(key)}</Link>
        </li>
      ))}
    </ul>
  );
};

export default PolicyList;
