import React, { FC, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { CheckCircleOutlined } from '@material-ui/icons';
import { MenuItem, InputLabel, Select, FormHelperText } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { DEFAULT_COUNTRY, DEFAULT_CURRENCY, DEFAULT_LANGUAGE, LAST_APIBUTTON_MARGIN } from '../../appConstants';
import APIButton from '../APIButton/APIButton';
import Alert from '../Alert/Alert';
import getUserInstance from '../../model/user';
import getKey from '../../utils/getKey';
import setSettingsCookie from '../../utils/setSettingsCookie';
import parseErrors from '../../utils/parse-error-messages';
import clearSettingsLogout from '../../utils/clear-settingns-logout';
import { SettingsData, AccountSettingsType } from '../../types';
import './AccountSettingsForm.scss';

interface AccountSettingsFormProps {
  settings: AccountSettingsType,
}

type FormInputs = {
  countryCode: string,
  currencyCode: string,
  languageCode: string,
};

type SelectType = {
  name: 'countryCode' | 'currencyCode' | 'languageCode',
  emptyName: 'choose-country' | 'choose-currency' | 'choose-language',
  options: SettingsData[],
  selectedValue: string,
  setter: React.Dispatch<React.SetStateAction<string>>,
  isDisabled: boolean,
};

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      maxHeight: 40,
    },
  }),
);

const UPDATE_SETTINGS_ERROR_KEY = 'update-settings-error-message';
const User = getUserInstance();

const AccountSettingsForm: FC<AccountSettingsFormProps> = ({ settings }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormInputs>({
    defaultValues: {
      countryCode: DEFAULT_COUNTRY,
      currencyCode: DEFAULT_CURRENCY,
      languageCode: '',
    }
  });
  const { t, i18n } = useTranslation();
  const [isLoader, setIsLoader] = useState(false);
  const [countries, setCountries] = useState<SettingsData[]>([]);
  const [currencies, setCurrencies] = useState<SettingsData[]>([]);
  const [languages, setLanguages] = useState<SettingsData[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT_COUNTRY);
  const [selectedCurrency, setSelectedCurrency] = useState(DEFAULT_CURRENCY);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  
  useEffect(() => {
    if (!countries.length) {
      User.getSettings('countries')
      .then((data: SettingsData[]) => {
        setCountries(data);
      });
    }
  }, [countries]);

  useEffect(() => {
    if (countries.length) {
      const selected = settings?.countryCode?.code || DEFAULT_COUNTRY;
      setSelectedCountry(selected);
      setValue('countryCode', selected);
    }
  }, [countries, setValue, settings]);

  useEffect(() => {
    if (!languages.length) {
      User.getSettings('languages')
      .then((data: SettingsData[]) => {
        setLanguages(data);
      });
    }
  }, [languages]);

  useEffect(() => {
    if (languages.length) {
      const selected = settings?.languageCode?.code || DEFAULT_LANGUAGE;
      setSelectedLanguage(selected);
      setValue('languageCode', selected);
    }
  }, [languages, settings, setValue]);

  useEffect(() => {
    if (!currencies.length) {
      User.getSettings('currencies')
      .then((data: SettingsData[]) => {
        const editedData: SettingsData[] = data.map(({ code }) => ({
          name: code,
          code
        }));
        setCurrencies(editedData);
      });
    }
  }, [currencies]);

  useEffect(() => {
    if (currencies.length) {
      const selected = settings?.currencyCode?.code || DEFAULT_CURRENCY;
      setSelectedCurrency(selected);
      setValue('currencyCode', selected);
    }
  }, [currencies, setValue, settings]);

  const classes = useStyles();
  function submitSettings({ countryCode, currencyCode, languageCode }) {
    setIsSuccessful(false);
    setIsLoader(true);
    User.updateSettings({ countryCode, currencyCode, languageCode })
    .then(() => {
      setSettingsCookie('country', countryCode);
      setSettingsCookie('currency', currencyCode);
      setIsSuccessful(true);
    })
    .catch((e) => {
      const message = parseErrors(e, UPDATE_SETTINGS_ERROR_KEY, i18n.exists);
      setFormErrorMessage(message);
      if (message === `401-${UPDATE_SETTINGS_ERROR_KEY}`) {
        clearSettingsLogout();
      }
    })
    .finally(() => setIsLoader(false));
  }
  const selectConfig:SelectType[] = [
    {
      name: 'countryCode',
      emptyName: 'choose-country',
      options: countries,
      selectedValue: selectedCountry,
      setter: setSelectedCountry,
      isDisabled: false,
    },
    {
      name: 'currencyCode',
      emptyName: 'choose-currency',
      options: currencies,
      selectedValue: selectedCurrency,
      setter: setSelectedCurrency,
      isDisabled: true,
    },
    {
      name: 'languageCode',
      emptyName: 'choose-language',
      options: languages,
      selectedValue: selectedLanguage,
      setter: setSelectedLanguage,
      isDisabled: false,
    },
  ];
  return (
    <form onSubmit={handleSubmit(submitSettings)} className="AccountSettingsForm">
      {
        selectConfig.map(({ name, emptyName, options, selectedValue, setter, isDisabled }) => (
          <Controller
            key={getKey()}
            control={control}
            name={name}
            rules={{ required: `${name}-required-error-message` }}
            render={({ field: { onChange, name } }) => {
              const errorMessage = errors[name]?.message;
              return (
                <>
                  <InputLabel id={name}>{t(name)}</InputLabel>
                  <Select
                    className={classes.select}
                    disabled={isDisabled}
                    fullWidth
                    displayEmpty
                    labelId={name}
                    value={selectedValue}
                    label={name}
                    name={name}
                    variant="outlined"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value && typeof value === 'string') {
                        onChange(value);
                        setter(value);
                      }
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>{t(emptyName)}</em>
                    </MenuItem>
                    { options.length && options.map(item => (
                      <MenuItem
                        value={item.code}
                        key={getKey()}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  { errorMessage &&
                    <FormHelperText error={true}>
                      {t(errorMessage)}
                    </FormHelperText>
                  }
                </>
              )}
            }
          />
        ))
      }
      <div className="AccountSettingsForm-submit">
        <APIButton
          isDisabled={isLoader}
          buttonText="save"
          errorMessage={formErrorMessage}
          type="submit"
          isLoader={isLoader}
          styles={LAST_APIBUTTON_MARGIN}
        />
      </div>
      {
        isSuccessful && 
        <div className="AccountSettingsForm-alert">
          <Alert
            icon={<CheckCircleOutlined/>}
            color="success"
            text="successful-setting-changed"
          />
        </div>
      }
    </form>
  )
};

export default AccountSettingsForm;
