import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { langState } from '../../recoil/atoms/lang';
import { authState } from '../../recoil/atoms/authorize';
import { domChange } from '../../recoil/atoms/dom-change';
import AuthorizeUser from '../../components/AuthorizeUser/AuthorizeUser';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import InvitationToRegistration from '../../components/InvitationToRegistration/InvitationToRegistration';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';
import TasksTable from '../../components/TasksTable/TasksTable';
import Loader from '../../components/Loader/Loader';
import getUserInstance from '../../model/user';
import { ITask } from '../../types';
import parseErrors from '../../utils/parse-error-messages';
import clearSettingsLogout from '../../utils/clear-settingns-logout';
import convertDate from './convertDate';
import './TasksPage.scss';

const GET_TASKS_ERROR_KEY = 'get-tasks-error-message';

const User = getUserInstance();

const TasksPage: FC<ITasksPageProps> = () => {
    const { i18n } = useTranslation();
    const [tasksList, setTasksList] = useState<ITask[]>([]);
    const [isLoader, setIsLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [authToken, setAuthToken] = useRecoilState(authState);
    const setIsDomChange = useSetRecoilState(domChange);
    const lang = useRecoilValue(langState);
    useEffect(() => {
        if (authToken) {
          setIsLoader(true);
          User.getTasks(authToken)
          .then((data: ITask[]) => {
            let res: ITask[] | undefined;
            data.forEach(item => {
              const itemTransformed = { ...item };
              if (item.created) {
                itemTransformed.dateTime = convertDate(item.created, lang);
              }
              if (res !== undefined) {
                res.push(itemTransformed);
              } else {
                res = [itemTransformed];
              };
            })
            if (res) {
              setTasksList(res);
            }
          })
          .catch((e) => {
            const message = parseErrors(e, GET_TASKS_ERROR_KEY, i18n.exists);
            setErrorMessage(message);
            if (message === `401-${GET_TASKS_ERROR_KEY}`) {
              clearSettingsLogout();
            }
          })
          .finally(() => setIsLoader(false))
          .then(() => setIsDomChange(true));
        }
    }, [authToken, lang, setIsDomChange, setAuthToken, i18n]);
    return (
        <>
            { authToken ? 
                <OneColumnLayout>
                    { isLoader ?
                        <Loader size="large" isWrapper={true} /> :
                        <TasksTable
                          tasksList={tasksList}
                          errorMessage={errorMessage}
                        />
                    }
                </OneColumnLayout> :
                <OneColumnLayout>
                    <DefaultGreeting/>
                    <AuthorizeUser/>
                    <InvitationToRegistration
                      text="register-invitation"
                      href="/register"
                    />
                </OneColumnLayout>
            }
        </>
    );
}

interface ITasksPageProps extends RouteComponentProps<{}> {}

export default TasksPage;
