import React from 'react';
import { useTranslation } from 'react-i18next';
import AFButton from '../AFButton/AFButton';
import {
  ButtonColorType,
  ButtonHandlerType,
  ButtonType,
  InlineStylesType,
} from '../../types';
import Loader from '../Loader/Loader';
import './BasicButton.scss';

export default function BasicButton(props: IBasicButtonProp) {
  const { t } = useTranslation();
  return (
    <AFButton
      styles={props.styles}
      variant="contained"
      color={props.color}
      href={props.href}
      type={props.type}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.isLoader ? 
        <Loader
          size="small"
        /> :
        t(props.text)
      }
    </AFButton>
  )
}

interface IBasicButtonProp {
  text: string,
  href?: string,
  type?: ButtonType,
  fullWidth?: boolean,
  disabled?: boolean,
  color?: ButtonColorType,
  onClick?: ButtonHandlerType,
  isLoader?: boolean,
  styles?: InlineStylesType,
}

BasicButton.defaultProps = {
  href: '',
  type: 'button',
  fullWidth: false,
  disabled: false,
  color: 'primary',
  onClick: undefined,
  isLoader: false,
  styles: {},
}
