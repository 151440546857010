import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import MainContent from '../../components/MainContent/MainContent';
import './MainPage.scss';

const MainPage: FC<IMainPageProps> = () => (
    <>
        <OneColumnLayout>
            <MainContent/>
        </OneColumnLayout>
    </>
);

interface IMainPageProps extends RouteComponentProps<{}> {}

export default MainPage;
