import req from "../../utils/req";

function verifyRequest(path, id) {
    const url = `${path}?token=${id}`;
    const reqData = {
        method: 'POST',
        headers: {
            'accept': '*/*',
        },
    }
    return req({ path: url, reqData })
}

export default verifyRequest;
