import Cookies from 'js-cookie'
import {
    authCookie,
    langCookie,
    currencyCookie,
    countryCookie
} from '../appConstants'

export default function clearCookie() {
    Cookies.remove(authCookie);
    Cookies.remove(langCookie);
    Cookies.remove(currencyCookie);
    Cookies.remove(countryCookie);
}
