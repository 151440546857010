import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { solutionsState } from '../../recoil/atoms/solutions';
import { solutionsWarningState } from '../../recoil/atoms/solutions-warning';
import { authState } from '../../recoil/atoms/authorize';
import { domChange } from '../../recoil/atoms/dom-change';
import AuthorizeUser from '../../components/AuthorizeUser/AuthorizeUser';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import ThreeColumnsLayout from '../../layout/ThreeColumnsLayout/ThreeColumnsLayout';
import InvitationToRegistration from '../../components/InvitationToRegistration/InvitationToRegistration';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';
import VerifyForm from '../../components/VerifyForm/VerifyForm';
import ProcessForm from '../../components/ProcessForm/ProcessForm';
import PaymentForm from '../../components/PaymentForm/PaymentForm';
import Loader from '../../components/Loader/Loader';
import parseErrors from '../../utils/parse-error-messages';
import clearSettingsLogout from '../../utils/clear-settingns-logout';
import getUserInstance from '../../model/user';
import {
  SolutionsType,
  TaskType,
  IFirmwareProperty,
  IInfo,
  OptionsType,
  IVerifyFormInputs
} from '../../types';
import setTaskState from './setTaskState';
import './Task.scss';

interface IRouteMatch {
  params: {
    id: number,
  }
  isExact: boolean,
  url: string,
  path: string,
}

interface ITaskProps extends RouteComponentProps<{}> {
  match: IRouteMatch,
}

const User = getUserInstance();
const PROCESS_ERROR_KEY = 'process-failed';
const GET_TASK_INFO_ERROR_KEY = 'get-task-info-error-message';
const VALIDATE_TASK_ERROR_KEY = 'get-validation-failed';

const Task: FC<ITaskProps> = (props) => {
    const { match: { params: { id } } } = props;
    const { i18n } = useTranslation();
    const checkWhetherKeyExists = i18n.exists;
    const authToken = useRecoilValue(authState);
    const solutionsWarning = useRecoilValue(solutionsWarningState);
    const setIsDomChange = useSetRecoilState(domChange);
    const isNotAuth = !authToken;
    const [taskInfo, setTaskInfo] = useState<TaskType>(null)
    const [selectedVehicle, setSelectedVehicle] = useState<IInfo>({ id: '', name: '' });
    const [selectedMake, setSelectedMake] = useState<IInfo>({ id: '', name: '' });
    const [selectedEngine, setSelectedEngine] = useState<IInfo>({ id: '', name: '' });
    const [selectedEcu, setSelectedEcu] = useState<IInfo>({ id: '', name: '' });
    const [selectedModel, setSelectedModel] = useState<IInfo>({ id: '', name: '' });
    const [vehicleOptions, setVehicleOptions] = useState<OptionsType>([]);
    const [makeOptions, setMakeOptions] = useState<OptionsType>([]);
    const [modelOptions, setModelOptions] = useState<OptionsType>([]);
    const [engineOptions, setEngineOptions] = useState<OptionsType>([]);
    const [ecuOptions, setEcuOptions] = useState<OptionsType>([]);
    const [statusName, setStatusName] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [removedCodes, setRemovedCodes] = useState('');
    const [selectedCodes, setSelectedCodes] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [currency, setCurrency] = useState('');
    const [taskInfoErrorMessage, setTaskInfoErrorMessage] = useState('');
    const [enabledSolutionsList, setEnabledSolutions] = useRecoilState(solutionsState);
    const [disabledSolutionsList, setDisabledSolutions] = useState<SolutionsType>([]);
    const [chosenSolutionsList, setChosenSolutions] = useState<SolutionsType>([]);
    const [firmwareProperties, setFirmwareProperties] = useState<IFirmwareProperty[]>([]);
    const [nonStock, setNonStock] = useState(false);
    const [isConfirmTaskButtonDisabled, setConfirmButtonDisabled] = useState(false);
    const [confirmTaskErrorMessage, setConfirmTaskError] = useState('');
    const [processErrorMessage, setProcessErrorMessage] = useState('');
    const [validationError, setValidationError] = useState('');
    const [isValidationLoader, setIsValidationLoader] = useState(false);
    const [isProcessingLoader, setIsProcessingLoader] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [inputFile, setInputFile] = useState<File>(new File([], 'file'));

    useEffect(() => {
      if (id && authToken) {
        setIsDataLoading(true);
        User.getTaskInfo(id, authToken)
        .then((data: TaskType) => {
          setTaskInfo(data);
        })
        .catch((e) => {
          const message = parseErrors(e, GET_TASK_INFO_ERROR_KEY,checkWhetherKeyExists);
          setTaskInfoErrorMessage(message);
          if (message === `401-${GET_TASK_INFO_ERROR_KEY}`) {
            clearSettingsLogout();
          }
        })
        .finally(() => setIsDataLoading(false))
        .then(() => setIsDomChange(true));
      }
    }, [id, authToken, setIsDomChange, checkWhetherKeyExists]);

    useEffect(() => {
      if (authToken && !vehicleOptions.length && !id && !taskInfoErrorMessage)
        User.getOptions({ name: 'types' })
        .then((data: OptionsType) => {
          setVehicleOptions(data);
        })
        .catch((e) => {
          const message = parseErrors(e, GET_TASK_INFO_ERROR_KEY,checkWhetherKeyExists);
          setTaskInfoErrorMessage(message);
        })
        .then(() => setIsDomChange(true));
    });

    useEffect(() => {
      if (taskInfo) {
        setTaskState({
          taskInfo,
          setVehicleOptions,
          setMakeOptions,
          setModelOptions,
          setEngineOptions,
          setEcuOptions,
          setSelectedVehicle,
          setSelectedMake,
          setSelectedEngine,
          setSelectedEcu,
          setSelectedModel,
          setStatusName,
          setIsVerified,
          setEnabledSolutions,
          setDisabledSolutions,
          setRemovedCodes,
          setSubtotal,
          setCurrency,
          setSelectedCodes,
          setFirmwareProperties,
          setChosenSolutions,
          setNonStock,
        });
      }
    }, [taskInfo, setIsDomChange, setEnabledSolutions]);

    function selectOptionHandler(e) {
      const { target } = e;
      const { name, value } = target;
      setSelectsData({ name, value })
    }

    function setSelectsData({ name, value }: { name: string, value: string }) {
      const setterCollection = {
        vehicle: {
          setter: setSelectedVehicle,
          next: 'makes',
          paramName: 'vehicleTypeId',
          nextOptionSetter: setMakeOptions,
          emptySetters: [setModelOptions, setEngineOptions, setEcuOptions],
          defaultSetters: [setSelectedMake, setSelectedModel, setSelectedEngine, setSelectedEcu],
        },
        make: {
          setter: setSelectedMake,
          next: 'models',
          paramName: 'makeId',
          nextOptionSetter: setModelOptions,
          emptySetters: [setEngineOptions, setEcuOptions],
          defaultSetters: [setSelectedModel, setSelectedEngine, setSelectedEcu],
        },
        engine: {
          setter: setSelectedEngine,
          next: 'ecus',
          paramName: 'engineId',
          nextOptionSetter: setEcuOptions,
          emptySetters: [],
          defaultSetters: [setSelectedEcu],
        },
        ecu: {
          setter: setSelectedEcu,
          next: '',
          paramName: '',
          nextOptionSetter: null,
          emptySetters: [],
          defaultSetters: [],
        },
        model: {
          setter: setSelectedModel,
          next: 'engines',
          paramName: 'modelId',
          nextOptionSetter: setEngineOptions,
          emptySetters: [setEcuOptions],
          defaultSetters: [setSelectedEngine, setSelectedEcu],
        }
      };
      const {
        setter,
        next,
        nextOptionSetter,
        paramName,
        emptySetters,
        defaultSetters
      } = setterCollection[name];
      setter({ id: value, name });
      if ( next ) {
        if (value) {
          const paramData = {
            name: next,
            param: {
              id: value,
              paramName,
            }
          }
          User.getOptions(paramData)
          .then((data: OptionsType) => {
            if (nextOptionSetter) {
              nextOptionSetter(data);
            }
          })
          .catch(() => {
            if (nextOptionSetter) {
              nextOptionSetter([]);
            }
          })
        } else {
          nextOptionSetter([]);
        }
      }
      if (defaultSetters.length) {
        defaultSetters.forEach(setter => setter({ id: '', name: '' }));
      }
      if (emptySetters.length) {
        emptySetters.forEach(setter => setter([]));
      }
    }

    function verifyFormHandler(data: IVerifyFormInputs) {
      const { model, engine, ecu } = data;
      setIsValidationLoader(true);
      User.validateTask({ model, engine, ecu, fileContent: inputFile, token: authToken, id })
      .then((data: TaskType) => {
        if (data) {
          props.history.replace({ pathname: `/task/${data.id}` });
          setTaskInfo(data);
        }
      })
      .catch((e) => {
        const message = parseErrors(e, VALIDATE_TASK_ERROR_KEY, checkWhetherKeyExists);
        setValidationError(message);
        if (message === `401-${VALIDATE_TASK_ERROR_KEY}`) {
          clearSettingsLogout();
        }
      })
      .finally(() => setIsValidationLoader(false))
      .then(() => setIsDomChange(true));
    }

    function confirmTaskHandler(id) {
      setConfirmButtonDisabled(true);
      User.confirmTask({ id, token: authToken })
      .then((data: TaskType) => {
        setTaskInfo(data);
      })
      .catch(() => {
        setConfirmTaskError('confirm-error');
      })
      .finally(() => setConfirmButtonDisabled(false))
      .then(() => setIsDomChange(true));
    }

    function processFormHandler({ codes }) {
      setProcessErrorMessage('');
      const rawCodes = codes.split(', ');
      const dtcCodes = rawCodes
      .filter(code => !!code)
      .map(code => code.trim());
      const solutionIds: number[] = [];
      enabledSolutionsList.forEach(({ id, selected }) => {
        if (selected) {
          solutionIds.push(id);
        }
      });
      if (solutionIds.length === 0 && dtcCodes.length === 0) {
        throw new Error('empty-inputs');
      }
      setIsProcessingLoader(true);
      User.processTask({
        id,
        token: authToken,
        solutionIds,
        dtcCodes,
      })
      .then((data: TaskType) => {
        setTaskInfo(data);
      })
      .catch((e) => {
        const message = parseErrors(e, PROCESS_ERROR_KEY, checkWhetherKeyExists);
        setProcessErrorMessage(message);
      })
      .finally(() => setIsProcessingLoader(false));
    }

    return (
        <>
            { !taskInfoErrorMessage && !isNotAuth && 
              <ThreeColumnsLayout
                left={<VerifyForm
                  status={statusName}
                  isVerified={isVerified}
                  firmwareProperties={firmwareProperties}
                  isNonStock={nonStock}
                  selectHandler={selectOptionHandler}
                  formHandler={verifyFormHandler}
                  message={validationError}
                  isLoader={isValidationLoader}
                  setFileContent={setInputFile}
                  fileName={taskInfo ? taskInfo!.fileInfo!.name : ''}
                  fileSize={taskInfo ? taskInfo!.fileInfo!.size : 0}
                  selects={[
                    {
                      name: 'vehicle',
                      options: vehicleOptions,
                      selectedValue: selectedVehicle,
                    },
                    {
                      name: 'make',
                      options: makeOptions,
                      selectedValue: selectedMake,
                    },
                    {
                      name: 'model',
                      options: modelOptions,
                      selectedValue: selectedModel,
                    },
                    {
                      name: 'engine',
                      options: engineOptions,
                      selectedValue: selectedEngine,
                    },
                    {
                      name: 'ecu',
                      options: ecuOptions,
                      selectedValue: selectedEcu,
                    },
                  ]}
                />}
                middle={ isVerified ? <ProcessForm
                  status={statusName}
                  enabledSolutions={enabledSolutionsList}
                  disabledSolutions={disabledSolutionsList}
                  removedCodes={removedCodes}
                  selectedCodes={selectedCodes}
                  submitFormHandler={processFormHandler}
                  errorMessage={processErrorMessage}
                  isLoader={isProcessingLoader}
                  solutionsWarning={solutionsWarning}
                /> : ''}
                right={<PaymentForm
                  status={statusName}
                  subtotal={subtotal}
                  currency={currency}
                  id={id}
                  isConfirmButtonDisabled={isConfirmTaskButtonDisabled}
                  confirmTaskErrorMessage={confirmTaskErrorMessage}
                  confirmTaskHandler={confirmTaskHandler}
                  solutions={chosenSolutionsList}
                  selectedCodes={selectedCodes}
                  removedCodes={removedCodes}
                />}
              />
            }
            {
              isNotAuth &&
              <OneColumnLayout>
                  <DefaultGreeting/>
                  <AuthorizeUser/>
                  <InvitationToRegistration
                    text="register-invitation"
                    href="/register"
                  />
              </OneColumnLayout>
            }
            {
              taskInfoErrorMessage &&
              <OneColumnLayout>
                <DefaultGreeting greetingText={taskInfoErrorMessage}/>
              </OneColumnLayout>
            }
            {
              isDataLoading &&
              <OneColumnLayout>
                <div className="task-loader">
                  <Loader
                    size="large"
                  />
                </div>
              </OneColumnLayout>
            }
        </>
    );
}

export default Task;
