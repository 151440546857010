import React, { FC } from 'react';
import getCaptchaKey from '../../recoil/atoms/get-captcha-key';
import './CaptchaCheckbox.scss';

interface CaptchaCheckboxProps {
  action: string,
}

const CaptchaCheckbox: FC<CaptchaCheckboxProps> = ({ action }) => {
  const key = getCaptchaKey();
  return (
    <div className="g-recaptcha"
      data-sitekey={key}
      data-action={action} />
)};

export default CaptchaCheckbox;
