import React from 'react';
import { TableCell } from '@material-ui/core';
import { IVehicleInfo } from '../../types';

export default function VehicleInfoCell(props: IVehicleInfoCellProp) {
  return (
    <TableCell className='task-link'>{`${props.info.vehicleType.name} / ${props.info.make.name} / ${props.info.model.name} / ${props.info.engine.name} / ${props.info.ecu.name}`}</TableCell>
  )
}

interface IVehicleInfoCellProp {
  info: IVehicleInfo
};