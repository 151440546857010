import i18n from 'i18next';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, langCookie, LANGUAGES } from '../appConstants';
import setSettingsCookie from '../utils/setSettingsCookie';

let lang = Cookies.get(langCookie) || '';

if (!lang) {
  lang = DEFAULT_LANGUAGE;
  setSettingsCookie('lang', DEFAULT_LANGUAGE);
}

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  lng: lang,
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
      main: require('./locales/en/main.json'),
    },
    ru: {
      translations: require('./locales/ru/translations.json'),
      main: require('./locales/ru/main.json'),
    }
  },
  ns: ['translations', 'main'],
  defaultNS: 'translations'
});

i18n.languages = LANGUAGES.map(({ code }) => code);

export default i18n;