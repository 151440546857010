import React from 'react'
import { Grid } from '@material-ui/core'
import './ThreeColumnsLayout.scss'

export default class ThreeColumnsLayout extends React.PureComponent<IThreeColumnsLayoutProps, IThreeColumnsLayoutState> {

  constructor(props: IThreeColumnsLayoutProps) {
    super(props);
    this.state = {
      lead: '',
      left: '',
      middle: '',
      right: '',
    }
  }

  // If you need 'shouldComponentUpdate' -> Refactor to React.Component
  // Read more about component lifecycle in the official docs:
  // https://reactjs.org/docs/react-component.html

  /*
  public shouldComponentUpdate(nextProps: IMyPageProps, nextState: IMyPageState) {
    // invoked before rendering when new props or state are being received.
    return true // or prevent rendering: false
  } */

  static getDerivedStateFromProps:
    React.GetDerivedStateFromProps<IThreeColumnsLayoutProps, IThreeColumnsLayoutState> = (props:IThreeColumnsLayoutProps, state: IThreeColumnsLayoutState) => {
      for ( let column in state) {
        state[column] = props[column];
      }
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    return null
  }

  public getSnapshotBeforeUpdate(prevProps: IThreeColumnsLayoutProps, prevState: IThreeColumnsLayoutState) {
    // invoked right before the most recently rendered output is committed
    // A snapshot value (or null) should be returned.
    return null
  }

  componentDidUpdate(prevProps: IThreeColumnsLayoutProps, prevState: IThreeColumnsLayoutState, snapshot: IThreeColumnsLayoutSnapshot) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.
  }

  render() {
    return (
      <div className="ThreeColumnsLayout">
        { this.state.lead }
        <Grid container spacing={4}>
          <Grid item xs={4}>
            { this.state.left }
          </Grid>
          <Grid item xs={4}>
            { this.state.middle }
          </Grid>
          <Grid item xs={4}>
            { this.state.right }
          </Grid>
        </Grid>
      </div>)
  }
}

interface IThreeColumnsLayoutProps {
  lead?: React.ReactNode,
  left: React.ReactNode,
  middle?: React.ReactNode,
  right?: React.ReactNode,
}

interface IThreeColumnsLayoutState {
  lead?: React.ReactNode,
  left: React.ReactNode,
  middle: React.ReactNode,
  right: React.ReactNode,
}

interface IThreeColumnsLayoutSnapshot {
  // TODO
}