import React, { FC } from 'react';
import AuthForm from "../AuthForm/AuthForm";
import { FormDataType } from '../../types';
import './RegisterUser.scss';


interface RegisterUserProps {
  handler: (data: FormDataType) => void,
  isDisabled: boolean,
  isRegistered: boolean,
  clear: () => void,
}

const RegisterUser: FC<RegisterUserProps> = ({ handler, isDisabled, isRegistered, clear }) => (
  <>
    <AuthForm
      formHandler={handler}
      isRegistration={true}
      errorMessage=''
      blurHandler={clear}
      disable={isDisabled}
    />
  </>
);

export default RegisterUser;
