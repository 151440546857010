import Cookies from 'js-cookie'
import {
    authCookie,
    loginPath,
    getTasksPath,
    revalidateTaskPath,
    registerPath,
    paymentsPath,
    optionsPath,
    providersPath,
    logoutPath,
    updateSettings,
} from '../../appConstants'
import req from '../../utils/req';
import clearCookie from '../../utils/clearCookie';
import { ITask, GetOptionsParam } from '../../types';

export type GetPaymentParam = {
    id: number,
    token: string,
    provider: string
};

export type ConfirmTaskParam = {
    id: number,
    token: string,
};

export type ProcessTaskParam = {
    id: number,
    token: string,
    solutionIds: number[],
    dtcCodes: string[]
};

export type ProviderType = {
    name: string,
    customHandling: boolean,
};

export type PaymentProvidersType = {
    paymentMethods: ProviderType[],
};

function downloadTask(id: number, token: string) {
    const path = `tasks/${id}/download`;
    const reqData = {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
    }
    return req({ path, reqData })
    .then((res) => {
        const headers = res.headers.get('content-disposition');
        return res.blob().then((file) => {
            const DEFAULT_FILE_NAME = 'default_file_name.bin';
            const fileName = headers?.replace('attachment; filename=', '') ?? DEFAULT_FILE_NAME;
            return { file, fileName };
        });
    })
    .then(({ file, fileName } : {file: Blob, fileName: string }) => {
        const href = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = href;
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(href);
        a.remove();
    })
    .catch((e) => {
        console.warn(`Download from ${path} failed. See detail: ${e}`);
        throw e;
    })
}

function getTaskInfo(id: number, token: string) {
    const path = `tasks/${id}`;
    const reqData = {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
    }
    return req({ path, reqData })
    .then((res) => res.json())
}

function getPaymentUrl({ id, token, provider }: GetPaymentParam) {
    const path = `${paymentsPath}?taskId=${id}&paymentProvider=${provider}`;
    const reqData = {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .then(({ url }: {url: string}) => url)
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`)
        throw e;
    });
}

function confirmTask({ id, token }: ConfirmTaskParam) {
    const path = `tasks/${id}/confirm`;
    const reqData = {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`)
        throw e;
    });
}

function processTask({ id, token, solutionIds, dtcCodes }: ProcessTaskParam) {
    const path = `tasks/${id}/process`;
    const reqData = {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            solutionIds,
            dtcCodes,
        }),
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`)
        throw e;
    });
}


function getOptions(data: GetOptionsParam ) {
    const { name, param } = data;
    const path = `${optionsPath}/${name}${param?.id && param?.paramName ? `?${param.paramName}=${param.id}` : ''}`;
    const reqData = {
        method: 'GET',
        headers: {
          'accept': 'application/json',
        },
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`);
        throw e;
    });
}

function validateTask({ model, engine, ecu, fileContent, token, id }) {
    const path = id ? `${revalidateTaskPath(id)}?modelId=${model}&engineId=${engine}&ecuId=${ecu}` : `${getTasksPath}?modelId=${model}&engineId=${engine}&ecuId=${ecu}`;
    const formData = new FormData();
formData.append('file', fileContent);
    const reqData = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`)
        throw e;
    });
}

function getCountries() {
    const path = 'i18n/countries';
    const reqData = {
        method: 'GET',
        headers: {
          'accept': 'application/json',
        },
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`)
        throw e;
    });
}

function getSettings(name) {
    const path = `i18n/${name}`;
    const reqData = {
        method: 'GET',
        headers: {
          'accept': 'application/json',
        },
    };
    return req({ path, reqData })
    .then((res) => res.json())
    .catch((e) => {
        console.warn(`Request to ${path} failed. See detail: ${e}`);
        throw e;
    });
}

class User {
    userName: string;

    authToken: string;

    tasks: ITask[] | [] | undefined;

    downloadTaskFile;

    getTaskInfo;

    openPaymentPage;

    confirmTask;

    processTask;

    getOptions;

    validateTask;

    getCountries;

    getSettings;


    static instance: User;

    constructor() {
        User.instance = this;
        this.userName = '';
        this.authToken = Cookies.get(authCookie) || '';
        this.tasks = [];
        this.downloadTaskFile = downloadTask;
        this.getTaskInfo = getTaskInfo;
        this.openPaymentPage = getPaymentUrl;
        this.confirmTask = confirmTask;
        this.processTask = processTask;
        this.getOptions = getOptions;
        this.validateTask = validateTask;
        this.getCountries = getCountries;
        this.getSettings = getSettings;
    }

    authorize({ username, password, token }: { username: string, password: string, token: string }) {
        const path = loginPath;
        const reqData = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
                reCaptchaResponse: token,
            }),
        }
        return req({ path, reqData })
        .then(res => res.text())
        .then((data) => {
            this.userName = username;
            this.authToken = data;
            Cookies.set(authCookie, data);
            return data
        })
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        })
    }

    register({ username, password, country, token, marketingConsent }: { username: string, password: string, country: string | undefined, token: string, marketingConsent: boolean }) {
        const path = registerPath;
        const reqData = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
                marketingConsent,
                countryCode: country,
                reCaptchaResponse: token,
            }),
        }
        return req({ path, reqData }).then(res => res.text())
        .then((data) => {
            this.userName = username;
            this.authToken = data;
            Cookies.set(authCookie, data);
            return data
        })
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        })
    }

    logOut() {
        console.log(`${this.userName} has been logged out`);
        const path = logoutPath;
        const reqData = {
            method: 'GET',
            headers: {
                'accept': '*/*',
                Authorization: `Bearer ${this.authToken}`,
            },
        }
        return req({ path, reqData })
        .then(() => {
            clearCookie();
            return true;
        })
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        });
    }

    getTasks(token) {
        const path = getTasksPath;
        const reqData = {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        }
        return req({ path, reqData })
        .then((res) => res.json())
        .then((data) => {
            this.tasks = data;
            return data
        })
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        })
    }

    getProviders() {
        const path = providersPath;
        const reqData = {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              Authorization: `Bearer ${this.authToken}`,
            },
        }
        return req({ path, reqData })
        .then((res) => res.json())
        .then((data: PaymentProvidersType) => data.paymentMethods)
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        });
    }

    updateSettings({ countryCode, languageCode, currencyCode }) {
        const path = updateSettings;
        const reqData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.authToken}`,
            },
            body: JSON.stringify({
                countryCode,
                languageCode,
                currencyCode,
            }),
        }
        return req({ path, reqData })
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        });
    }

    getAccountSettings() {
        const path = updateSettings;
        const reqData = {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                Authorization: `Bearer ${this.authToken}`,
            },
        }
        return req({ path, reqData })
        .then((res) => res.json())
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`)
            throw e;
        });
    }

    getWalletBalance() {
        const path = 'payments/walletBalance';
        const reqData = {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              Authorization: `Bearer ${this.authToken}`,
            },
        };
        return req({ path, reqData })
        .then((res) => res.json())
        .catch((e) => {
            console.warn(`Request to ${path} failed. See detail: ${e}`);
            throw e;
        })
    }

    handleWalletPaymentUrl(url: string) {
        const reqData = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.authToken}`,
            },
        };
        return req({ path: url, reqData })
        .catch((e) => {
            console.warn(`Request to ${url} failed. See detail: ${e}`)
            throw e;
        });
    }
}

function getUserInstance() {
    if (User.instance) return User.instance
    return new User()
}

export default getUserInstance;
