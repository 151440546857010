export default function parseErrors(e, errorKey, checkKey) {
    const errorResponse = e.message;
    let errorMessage = '';
    if (errorResponse) {
      try {
        const errorConfig = JSON.parse(errorResponse) ;
        const { errorCode, status, message } = errorConfig;
        if (errorCode || status) {
          const code = errorCode || status;
          errorMessage = `${code}-${errorKey}`;
          if (!checkKey(errorMessage)) {
            errorMessage = `Error ${code}: ${message}`;
          }
        } else {
          errorMessage = errorConfig.message;
        }
      } catch {
        errorMessage = errorResponse;
      }
    } else {
      errorMessage = `default-${errorKey}`;
    }
    return errorMessage;
}
