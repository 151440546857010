import Cookies from 'js-cookie';
import { langCookie } from '../appConstants';

const cookieNames = {
    lang: langCookie,
}

export default function setSettingsCookie(action: string, value: string) {
    const cookieName = cookieNames[action];
    Cookies.set(cookieName, value);
}
