import React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import { ButtonColorType, ButtonHandlerType, InlineStylesType } from '../../types';


export default function AFButton(props: IAFButtonProp) {
  const CustomButton = styled(Button)({
    textTransform: 'none',
    minHeight: 40,
    color: props.textColor,
    '&:hover': {
      color: props.textColor,
    },
  });
  return (
    <CustomButton
      style={props.styles}
      variant={props.variant}
      color={props.color}
      href={props.href}
      startIcon={props.startIcon}
      type={props.type}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </CustomButton>
  )
}

interface IAFButtonProp {
  variant: 'text' | 'outlined' | 'contained',
  href?: string,
  color?: ButtonColorType,
  startIcon?: React.ReactNode,
  children?: React.ReactNode,
  type?: 'button' | 'submit' | 'reset',
  fullWidth?: boolean,
  disabled?: boolean,
  onClick?: ButtonHandlerType,
  styles?: InlineStylesType,
  textColor?: string,
}

AFButton.defaultProps = {
  href: '',
  color: 'default',
  startIcon: null,
  children: null,
  type: 'button',
  fullWidth: false,
  disabled: false,
  onClick: undefined,
  styles: {},
  textColor: '#fff',
}
