import React, { FC } from 'react';
import { Typography, List, ListItemText } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import './MainContent.scss';

interface MainContentProps {
  t: (key: string) => string;
}

const MainContent: FC<MainContentProps> = ({ t }) => (
  <div className="MainContent">
    <Typography paragraph>{ t('0') }</Typography>
    <Typography paragraph>{ t('1') }</Typography>
    <Typography component="h2" variant="h6">{ t('2') }</Typography>
    <Typography paragraph>{ t('3') }</Typography>
    <Typography component="h2" variant="h6">{ t('4') }</Typography>
    <Typography paragraph>{ t('5') }</Typography>
    <Typography component="h2" variant="h6">{ t('6') }</Typography>
    <Typography paragraph>{ t('7') }</Typography>
    <List>
      <ListItemText inset={ true }>- { t('8') }</ListItemText>
      <ListItemText inset={ true }>- { t('9') }</ListItemText>
      <ListItemText inset={ true }>- { t('10') }</ListItemText>
      <ListItemText inset={ true }>- { t('11') }</ListItemText>
      <ListItemText inset={ true }>- { t('12') }</ListItemText>
      <ListItemText inset={ true }>- { t('13') }</ListItemText>
      <ListItemText inset={ true }>- { t('14') }</ListItemText>
      <ListItemText inset={ true }>- { t('15') }</ListItemText>
      <ListItemText inset={ true }>- { t('16') }</ListItemText>
      <ListItemText inset={ true }>- { t('17') }</ListItemText>
      <ListItemText inset={ true }>- { t('18') }</ListItemText>
    </List>
    <Typography component="h2" variant="h6">{ t('19') }</Typography>
    <Typography paragraph>{ t('20') }</Typography>
    <Typography component="h2" variant="h6">{ t('21') }</Typography>
    <Typography paragraph>{ t('22') }</Typography>
    <Typography component="h2" variant="h6">{ t('23') }</Typography>
    <Typography paragraph>{ t('24') }</Typography>
    <Typography component="h2" variant="h6">{ t('25') }</Typography>
    <Typography paragraph>{ t('26') }</Typography>
    <Typography paragraph><i>{ t('27') }</i></Typography>
    <Typography paragraph><i>{ t('28') }</i></Typography>
  </div>
  );

export default withTranslation('main')(MainContent);
