import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, ErrorOutlineOutlined } from '@material-ui/icons';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import {
    verifyToken,
    resendToken,
    EMAIL_CONFIRMATION_VALID,
    EMAIL_CONFIRMATION_INVALID,
    EMAIL_CONFIRMATION_EXPIRED,
} from '../../appConstants';
import parseErrors from '../../utils/parse-error-messages';
import ButtonErrorMessage from '../../components/ButtonErrorMessage/ButtonErrorMessage';
import Loader from '../../components/Loader/Loader';
import Alert from '../../components/Alert/Alert';
import verifyRequest from './request';
import './VerifyEmailPage.scss';

const VERIFY_EMAIL_ERROR_KEY = 'verify-email-error-message';
const RESEND_TOKEN_ERROR_KEY = 'resend-token-error-message';

const VerifyEmailPage: FC<IVerifyEmailPageProps> = () => {
    const { i18n } = useTranslation();
    const [status, setStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [isResendAlert, setIsResendAlert] = useState(false);
    useEffect(() => {
        setIsLoader(true);
        const params = (new URL(window.document.location.href)).searchParams;
        const id = params.get('id');
        verifyRequest(verifyToken, id)
        .then((res) => res.json())
        .then(({ verificationResult }: {verificationResult: string}) => {
            setStatus(verificationResult);
            return verificationResult;
        })
        .then((status) => {
            if (status === EMAIL_CONFIRMATION_EXPIRED) {
                verifyRequest(resendToken, id)
                .then((res) => res.ok)
                .then(() => {
                    setIsResendAlert(true);
                })
                .catch((e) => {
                    const message = parseErrors(e, RESEND_TOKEN_ERROR_KEY, i18n.exists);
                    setErrorMessage(message);
                });
            }
        })
        .catch((e) => {
            const message = parseErrors(e, VERIFY_EMAIL_ERROR_KEY, i18n.exists);
            setErrorMessage(message);
        })
        .finally(() => setIsLoader(false));
    }, [i18n]);
    return (
        <>
            <OneColumnLayout>
                {isLoader ?
                    <Loader size="large" isWrapper={true} /> :
                    <>
                        <div className="VerifyEmail-message">
                            { status === EMAIL_CONFIRMATION_INVALID && <Alert
                                icon={<ErrorOutlineOutlined/>}
                                color="error"
                                text="failed-email-verification"
                                isMarginBottom={true}
                                isFitContent={true}
                            /> }
                            { status === EMAIL_CONFIRMATION_VALID && <Alert
                                icon={<CheckCircleOutlined/>}
                                color="success"
                                text="successful-email-verification"
                                isMarginBottom={true}
                                isFitContent={true}
                            /> }
                            { status === EMAIL_CONFIRMATION_EXPIRED && <Alert
                                icon={<ErrorOutlineOutlined/>}
                                color="error"
                                text="expired-token"
                                isMarginBottom={true}
                                isFitContent={true}
                            /> }
                            {
                                isResendAlert && <Alert
                                icon={<CheckCircleOutlined/>}
                                color="success"
                                text="resend-email-confirmation"
                                isFitContent={true}
                            />
                            }
                            { errorMessage && <ButtonErrorMessage text={errorMessage}/> }

                        </div>
                    </>
                }
            </OneColumnLayout>
        </>
)};

interface IVerifyEmailPageProps extends RouteComponentProps<{}> {}

export default VerifyEmailPage;
