import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';

const PageNotFound: FC<IPageNotFoundProps> = () => (
    <OneColumnLayout>
        <DefaultGreeting greetingText="page-not-found"/>
    </OneColumnLayout>
);

interface IPageNotFoundProps extends RouteComponentProps<{}> {}

export default PageNotFound;
