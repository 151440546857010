import React from 'react';
import { MemoryOutlined } from  '@material-ui/icons';
import { useForm } from "react-hook-form";
import PricesSolutionsList from './PricesSolutionsList';
import APIButton from '../APIButton/APIButton';
import { IPriceSolutionsInputs } from '../../types';
import { LAST_APIBUTTON_MARGIN } from '../../appConstants';
import './PricesSolutionsForm.scss';

export default function ProcessForm({
  submitFormHandler,
  isLoader,
  isDisabled,
}: IPricesSolutionsFormProp) {
  const {
    control,
    handleSubmit,
    setError,
    getValues,
  } = useForm<IPriceSolutionsInputs>();

  function setEmptyError() {
    const root = getValues();
    if (root) {
      const inputs = Object.keys(root);
      inputs.forEach(input => setError(input, { type: 'empty-form', message: 'empty-form' }))
    }
  }

  const formErrors = {
    'empty-inputs': setEmptyError,
  }

  return (
    <form className="ProcessForm" onSubmit={(e) => {
      handleSubmit(submitFormHandler)(e)
      .catch((e) => {
        const { message } = e;
        formErrors[message]();
      });
    }}>
      <div className="ProcessList">
        <PricesSolutionsList
          icon={<MemoryOutlined/>}
          disabled={false}
          title='processing-available'
          control={control}
        />
      </div>
      <APIButton
        isDisabled={isDisabled}
        buttonText='getPrice'
        type="submit"
        isLoader={isLoader}
        styles={LAST_APIBUTTON_MARGIN}
      />
    </form>
  )
}

interface IPricesSolutionsFormProp {
  submitFormHandler: (data: IPriceSolutionsInputs) => void,
  isLoader: boolean,
  isDisabled: boolean,
}
