import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SummaryIcon from '../SummaryIcon/SummaryIcon';
import InfoBlock from '../InfoBlock/InfoBlock';
import { SolutionsType } from '../../types';
import getKey from '../../utils/getKey';
import './SummaryBlock.scss';

interface SummaryBlockProps {
  solutions: SolutionsType,
  removedCodes: string,
  selectedCodes: string,
  className?: string,
}

const SummaryBlock: FC<SummaryBlockProps> = ({ solutions, selectedCodes, removedCodes, className }) => {
  const { t } = useTranslation();
  return <InfoBlock
    icon={<SummaryIcon/>}
    title="summary"
    isSeparateTitle={true}
    className={className}
  >
    <table className="summary">
      <tbody>
        {solutions.length ? 
          <tr className="summary-category">
            <td className="summary-category-name">{t('solutions')}:</td>
            <td className="summary-category-value">
              <ul className="summary-value-list">
                {
                  solutions.map(({ name, description }) => (
                    <li key={getKey()} className="summary-value-item">
                      {name} ({description})
                    </li>
                  ))
                }
              </ul>
            </td>
          </tr> : false
        }
        {
          selectedCodes.length ?
          <>
            <tr className="summary-category">
                <td className="summary-category-name">{t('selected-dtcs')}:</td>
                <td className="summary-category-value">
                  {selectedCodes}
                </td>
            </tr>
            <tr className="summary-category">
              <td className="summary-category-name">{t('removed-dtcs')}:</td>
              <td className="summary-category-value">
                {removedCodes.length ? removedCodes : t('none')}
              </td>
            </tr>
          </> : false
        }
      </tbody>
    </table>
  </InfoBlock>
};

SummaryBlock.defaultProps = {
  className: '',
};

export default SummaryBlock;
