import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Autorenew } from '@material-ui/icons';
import { IconSizeType } from '../../types';
import './Loader.scss';

interface LoaderProps {
  size: IconSizeType,
  text?: string,
  isWrapper?: boolean,
}

const Loader: FC<LoaderProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.isWrapper ? 
        <div className="loader-wrapper">
          <div className="loader">
            <Autorenew fontSize={props.size}/>
          </div>
          {props.text && t(props.text)}
        </div> :
        <>
          <div className="loader">
            <Autorenew fontSize={props.size}/>
          </div>
          {props.text && t(props.text)}
        </>
      }
    </>
  )
};

Loader.defaultProps = {
  text: '',
  isWrapper: false,
};

export default Loader;
