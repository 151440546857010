import { HOST_API } from '../appConstants'
import clearCookie from './clearCookie'

interface SendDataType {
  path: string,
  reqData: {
    method: string,
    headers?: {
      'Content-Type'?: string,
      accept?: string,
      Authorization?: string,
    },
    body?: BodyInit,
  },
}

function req({ path, reqData }: SendDataType) {
  const url = `${HOST_API}${path}`;
  return fetch(url, {
    mode: 'cors',
    credentials: 'include',
    ...reqData
  })
  .then(async (res) => {
    if (res.ok) return res;
    let message = '';
    if (res.status === 401) {
      clearCookie();
      window.location.href = '/login';
    }
    try {
      const errorConfig = await res.json();
      message = JSON.stringify(errorConfig);
    } catch {
      message = await res.text();
    }
    throw new Error(message);
  })
  .catch(e => {
    throw new Error(e)
  })
}

export default req
