import React, { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { OFFER_URL } from '../../appConstants';
import { domChange } from '../../recoil/atoms/dom-change';
import './Footer.scss';

interface FooterProps {};

function checkDocumentHeight() {
  const { body } = window.document;
  const html = window.document.documentElement;
  const windowHeight = window.innerHeight;
  const documentHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
  return documentHeight > windowHeight;
}

const Footer: FC<FooterProps> = () => {
  const [isDomChange, setIsDomChange] = useRecoilState(domChange);
  const [modificator, setModificator] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    const isBigger = checkDocumentHeight();
    const modValue = isBigger ? ' Footer-sticky' : ' Footer-fixed';
    setModificator(modValue);
  }, []);
  useEffect(() => {
    if(isDomChange) {
      const isBigger = checkDocumentHeight();
      const modValue = isBigger ? ' Footer-sticky' : ' Footer-fixed';
      setModificator(modValue);
      setIsDomChange(false);
    }
  }, [isDomChange, setIsDomChange]);
  return (
    <footer className={`Footer${modificator}`}>
      <div className="inner-footer">
        <ul className="contacts-list">
          <li className="contact">
            <Link href="/prices">
              {t('prices')}
            </Link>
          </li>
          <li className="contact">
            <Link href="/policy">
              {t('policy')}
            </Link>
          </li>
          { process.env.REACT_APP_HOST === 'autofirmware.ru' &&
            <li className="contact">
              <Link href={OFFER_URL}>
                {t('offer')}
              </Link>
            </li>
          }
          <li className="contact">
            <Link href="/contacts">
              {t('contacts')}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
};

export default Footer;
