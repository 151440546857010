import React, { FC } from 'react';
import { RemoveCircleOutline } from '@material-ui/icons';
import { TextField, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import Title from '../Title/Title';
import { ProcessFormFieldType, ProcessFormErrorsType } from '../../types';
import './RemovalInputs.scss';

interface RemovalInputsProps {
  removedCodes: string,
  control: ProcessFormFieldType,
  errors: ProcessFormErrorsType,
}

const RemovalInputs: FC<RemovalInputsProps> = ({ removedCodes, control, errors }) => {
  const { t } = useTranslation();
  return (
    <div className="RemovalInputs">
      <Title
        title='dtc-removal'
        icon={<RemoveCircleOutline/>}
      />
      <FormHelperText
        style={{
          lineHeight: 1.3,
          marginBottom: '5px',
        }}
      >
        {t('dtc-codes-instructions')}
      </FormHelperText>
      <Controller
        control={control}
        name='codes'
        render={({ field: { onChange, name, value } }) => {
          const errorMessage = errors[name]?.message;
          return (
            <TextField
              name={name}
              label={t('input-dtc')}
              onChange={onChange}
              value={value}
              fullWidth
              variant="outlined"
              margin="dense"
              error={ !!errorMessage }
              helperText={ errorMessage && t(errorMessage) }
            />
          )
        }}
      />
      <TextField
        name='removed-dtc'
        label={t('removed-dtc')}
        fullWidth
        variant="outlined"
        margin="dense"
        disabled={true}
        value={removedCodes}
      />
    </div>
  )
};

export default RemovalInputs;
