import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../recoil/atoms/authorize';
import OneColumnLayout from '../../layout/OneColumnLayout/OneColumnLayout';
import ThreeColumnsLayout from '../../layout/ThreeColumnsLayout/ThreeColumnsLayout';
import DefaultGreeting from '../../components/DefaultGreeting/DefaultGreeting';
import AuthorizeUser from '../../components/AuthorizeUser/AuthorizeUser';
import InvitationToRegistration from '../../components/InvitationToRegistration/InvitationToRegistration';
import AccountSettingsForm from '../../components/AccountSettingsForm/AccountSettingsForm';
import getUserInstance from '../../model/user';
import { AccountSettingsType } from '../../types';
import './AccountSettingsPage.scss';

const User = getUserInstance();
const AccountSettingsPage: FC<IAccountSettingsProps> = () => {
    const authToken = useRecoilValue(authState);
    const [predefinedSettings, setPredefinedSettings] = useState<AccountSettingsType>(null);
    useEffect(() => {
        User.getAccountSettings()
        .then((settings: AccountSettingsType) => {
            setPredefinedSettings(settings);
        })
    }, [authToken])
    return (
        <>
            { authToken ? 
                <ThreeColumnsLayout
                    left={<AccountSettingsForm
                        settings={predefinedSettings}
                    />}
                /> :
                <OneColumnLayout>
                    <DefaultGreeting/>
                    <AuthorizeUser/>
                    <InvitationToRegistration
                        text="register-invitation"
                        href="/register"
                    />
                </OneColumnLayout>
            }
        </>
    )
}

interface IAccountSettingsProps extends RouteComponentProps<{}> {}

export default AccountSettingsPage;
