import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Typography, FormHelperText } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { useRecoilState } from 'recoil';
import { solutionsState } from '../../recoil/atoms/solutions';
import { solutionsWarningState } from '../../recoil/atoms/solutions-warning';
import { ProcessFormFieldType, ProcessFormErrorsType, ISolution } from '../../types';
import './ProcessCheckboxItem.scss';

export interface ProcessCheckboxItemProps {
  name: string,
  selected: boolean,
  description: string,
  comment: string,
  disabled: boolean,
  id: number,
  control: ProcessFormFieldType,
  errors: ProcessFormErrorsType,
  isUnavailable: boolean,
  dependsOn: string[],
}

const PERIOD_OF_SHOWING_SOLUTION_WARNING = 15000;
const ProcessCheckboxItem: FC<ProcessCheckboxItemProps> = ({ name: nameCheckbox, selected, description, comment, disabled, id, control, errors, isUnavailable, dependsOn }) => {
  const { t } = useTranslation();
  const [currentSolutions, setCurrentSolutions] = useRecoilState(solutionsState);
  const [currentSolutionWarning, setSolutionsWaning] = useRecoilState(solutionsWarningState);
  const currentCheckboxIndex = currentSolutions.findIndex((item) => item.id === id);
  const solutionsCopy = [...currentSolutions];
  const dependentIndexes: number[] = dependsOn.map((dependentItem) => currentSolutions.findIndex((item) => item.name === dependentItem));
  let dependentIndexesLen = dependentIndexes.length
  return (
    <div className="ProcessCheckboxItem">
      <Controller
        control={control}
        name={id.toString()}
        defaultValue={isUnavailable ? false : selected}
        render={({ field: { onChange, name, value } }) => {
          const errorMessage = errors[name]?.message;
          return (
            <>
              <FormControlLabel
                control={<Checkbox
                  value={id}
                  onChange={(e) => {
                    if (currentSolutionWarning) {
                      window.clearTimeout(currentSolutionWarning.timeoutId)
                      setSolutionsWaning(null);
                    }
                    const isChecked = e.target.checked;
                    const newItem: ISolution = {
                      ...solutionsCopy[currentCheckboxIndex],
                      selected: isChecked,
                    };
                    solutionsCopy.splice(currentCheckboxIndex, 1, newItem);
                    if (isChecked && dependentIndexesLen > 0) {
                      dependentIndexes.forEach((index) => {
                        if (solutionsCopy[index].selected === true) {
                          dependentIndexesLen -= 1
                        } else {
                          const updatedItem = {
                            ...solutionsCopy[index],
                            selected: true,
                          };
                          solutionsCopy.splice(index, 1, updatedItem);
                        }
                      })
                    }
                    if (!isChecked && currentSolutionWarning?.name === nameCheckbox) {
                      window.clearTimeout(currentSolutionWarning.timeoutId)
                      setSolutionsWaning(null);
                    }

                    if (dependentIndexesLen > 0 && isChecked) {
                      const warningData = {
                        name: nameCheckbox,
                        dependsOn,
                        timeoutId: 0,
                      }
                      warningData.timeoutId = window.setTimeout(() => {
                        setSolutionsWaning(null);
                      }, PERIOD_OF_SHOWING_SOLUTION_WARNING);
                      setSolutionsWaning(warningData);
                    }
                    setCurrentSolutions(solutionsCopy);
                    onChange(isChecked);
                  }}
                  inputProps={{ 'aria-label': name }}
                  checked={selected}
                  color="primary"
                />}
                label={`${nameCheckbox} (${description})`}
                disabled={disabled}
              />
              {
                errorMessage && !disabled &&
                <FormHelperText
                  error={true}
                >
                  { t(errorMessage) }
                </FormHelperText>
              }
            </>
          )
        }}
      />
      { comment &&
        <Typography
          variant="caption"
          className="ProcessCheckboxItem-comment"
          style={{
            lineHeight: 1.3,
            marginBottom: '10px',
          }}
        >
          <InfoOutlined
            fontSize="inherit"
            style={{
              verticalAlign: 'top',
              paddingTop: '1px',
            }}
          /> {comment}
        </Typography>
      }
    </div>
  )
};

export default ProcessCheckboxItem;
