import req from "../../utils/req";

export function getSolutions(ecu: string) {
    const url = `pricing/definedSolutions?&ecuId=${ecu}`;
    const reqData = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
        },
    }
    return req({ path: url, reqData })
    .then(res => res.json())
    .then(({ solutions }) => solutions);
}

export function getPrice(engine: string, ecu: string, codes: string) {
    const url = `pricing/calcPrice?engineId=${engine}&ecuId=${ecu}&solutions=${codes}`;
    const reqData = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
        },
    }
    return req({ path: url, reqData })
    .then(res => res.json())
    .then(({ price }) => price);
}
